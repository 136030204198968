import React from 'react';
import MobileResume from '../../components/mobileResume/mobileResume';
import resume from '../../kyraResumeNew.pdf';
import style from './resume.module.scss';
import ReactGA from 'react-ga';

export default function Resume() {
  const [showMobileResume, setShowMobileResume] = React.useState(null);
  const [userToggle, setUserToggle] = React.useState(false);
  ReactGA.pageview(window.location.pathname + window.location.search);

  const checkIsMobile = React.useCallback(() => {
    if(window.innerWidth <= 500 && userToggle !== true) {
      setShowMobileResume(true)
    } else if(window.innerWidth > 500) {
      setShowMobileResume(false)
    };
  }, [userToggle])

  React.useEffect(()=> {
    checkIsMobile();
    window.scrollTo({top: 0})
  },[checkIsMobile]);

  const handleClick = () => {
    setUserToggle(true);
    setShowMobileResume(false);
  }

  window.addEventListener('resize', checkIsMobile);

  return (<>
      {
      showMobileResume ? 
        <main className={style.mobileContainer}>
          <MobileResume handleClick={handleClick} />
        </main>
      : <main className={style.iframeContainer}>
          <iframe title="Kyra's resume" src={resume} style={{border: 0}}  width="100%" height="100%" />
        </main>
      }
  </>
  )
}
