import React from 'react';
import { BlackButton } from '../buttons/buttons.js';
import style from './mobileResume.module.scss';

export default function MobileResume({ handleClick}) {
  return (
    <section className={style.mobileResume}>
        <h2>Selected Experience</h2>
        <hr className={style.lineBreak}/>
        <article>
            <h3 className={style.firstH3}>Product Designer, <span>Campus Dive</span></h3>
            <h4>B2C Edtech Platform - Responsive Web Design & Feature Design</h4>
            <ul>
                <li>Redesigned current platform including developing a <b>style guide, design system</b> and end-to-end UX design to improve usability of current features & new product launch</li>
                <li><b>Lead the data visualization design</b> strategy for 100+ data points</li>
                <li>Collaborated on redesign of intake process that <b>decreased time on task by 75%</b></li>
                <li>Worked with <b>dev & product teams</b> to inform product strategy & design handoff through AGILE sprint cycles</li>
            </ul>
        </article>
        <article>
            <h3 className={style.firstH3}>UI/UX Design, <span>NBNB Golf Club</span></h3>
            <h4>Sports Social - Native Mobile App - Mobile & Web Platform Product Launch</h4>
            <ul>
                <li><b>Informed product direction for launch</b> by aligning user needs, cross-department stakeholder ideas, and business requirements for MVP</li>
                <li><b>Conducted 50+ hours of user research</b> including 1:1 interviews, usability testing and other methodologies to inform design strategy</li>
                <li>Lead <b>end-to-end feature design</b> including journey mapping, persona development,  ideation, wireframing, prototyping, testing and implementation guidelines</li>
                <li><b>Lead branding & visual strategy</b> to reflect brand ethos of inclusion & representation</li>
            </ul>
        </article>
        <article>
            <h3>Freelance Product Designer, <span>KBG Design</span></h3>
            <p className={style.actLikeLi}>Adaptive work with teams and independently to support business and user goals. UX research and UI/UX design for
             web and mobile app products in diverse sectors for clients including:</p>
            
            <h4>Brand & Visual Designer, EH Consulting <span>- multi-service Edtech Consultancy</span></h4> 
            <ul>
                <li><b>Logo, branding & visual strategy design</b> for consulting firm</li>
            </ul>
            <h4>UX Designer, Earth Hero <span>- Climate Action mobile app with over 50k+ downloads</span></h4> 
            <ul>
                <li><b>Collaborated with cross-functional</b> teams including product, research, and development teams to improve onboarding experience</li>
            </ul>
            <h4>UX Research Consultant, Lavender Library<span>- LGBTQ+  Non-Profit and digital archive database</span></h4> 
            <ul>
                <li><b>Lead implementation of diverse UX research methodologies</b> that improved sitemap by reducing bounce rate by 33%</li>
            </ul>
        </article> 
        <article>
            <h3>Art Teacher</h3>
            <h4>Twin Rivers & Stockton Unified School Districts	</h4>
            <ul>
                <li><b>Lead equity-based education strategies</b> for diverse arts curriculum</li>
                <li>Integrated learning strategies with <b>edtech tools</b> to better support and engage students</li>
            </ul>
        </article>
        <h2 className={style.second}>Education</h2>
        <hr className={style.lineBreak}/>
        <article>
            <h3 className={style.firstH3}>UX Design Academy & Foundations</h3>
            <h4>Designlab</h4>
            <ul>
                <li>Completed 600+ hours of coursework, full-stack design training, and 4 capstone projects</li>
            </ul>
        </article>
        <article>
            <h3>Teaching Licensce, K-12 Art Education</h3>
            <h4>Loyola Marymount University</h4>
        </article>   
        <article>
            <h3>B.A. with honors, Art & Gender Studies</h3>
            <h4>New College of Florida</h4>
        </article>
        <div className={style.buttonContainer}>
            <BlackButton onClick={handleClick} text="View Full Resume" />
        </div>
    </section>
  )
}
