import React from 'react';
import style from './aboutMeValues.module.scss';

const AboutMeValues = () => {
  return (
    <section className={style.container} id="values">
        <h2>My Design Values</h2>
        <div className={style.articleWrapper}>
            <article className={style.valuesWrapper}>
                <h3>🌎 Create a positive impact</h3>
                <p>I’m driven to do the impossible when I know what I am building matters. I value working on products that benefit user’s daily lives and positively impact the world and our environment.</p>
            </article>
            <article className={style.valuesWrapper}>
                <h3>🧠 Always be learning.</h3>
                <p>When we stop learning, we  start dying. Dramatic, yes but also true. I am commited to my professional and personal growth and learn so much from my colleages and the user’s I serve.</p>
            </article>
            <article className={style.valuesWrapper}>
                <h3>❤️ Equity, inclusion & Accessibility</h3>
                <p>Design is a powerful tool, and begets serious responsibility. I am commited to designing accessible solutions that lead with equity on the onset, not an afterthouoght.</p>
            </article>
            <article className={style.valuesWrapper}>
                <h3>🙌 Remain humble</h3>
                <p className={style.lastP}>As a leader, manager and colleague I believe that respect is fundamental to thriving relationships. Humility leads to strong, kinder communication and that is key to success.</p>
            </article>
        </div>
    </section>
  )
}

export default AboutMeValues