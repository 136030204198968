import React from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import style from './mobileNav.module.scss';

export default function MobileNav({ isOpen, closeDrawer}) {

  const handleClick = () => {
    closeDrawer();
  }

  return (
    <nav className={`${style.mobileNav} ${!isOpen ? style.show : ""}`}>
      <ul className={style.navUL}>
        <li className={style.headerLI}><Link onClick={handleClick} to="/portfolio">Portfolio</Link></li>
        <li className={style.headerLI}><Link onClick={handleClick} to="/about">About</Link></li>
        <li className={style.headerLI}><NavHashLink smooth onClick={handleClick} to="/about#contact">Contact</NavHashLink></li>
        <li className={style.headerLI}><Link onClick={handleClick} to="/resume">Resume</Link></li>
      </ul>
    </nav>
  )
}
