import React from 'react';
import mny from '../../../assets/images/MNY-header.png';
import projectBackground from '../../../assets/images/MNY_ProjectBackground.png';
import analysis from '../../../assets/images/MNY_CompetitiveAnalysis.png';
import design from '../../../assets/images/MNY_EmpatheticDesign.png';
import userFlow1 from '../../../assets/images/MNY_UserFlow1.png';
import userFlow2 from '../../../assets/images/MNY_UserFlow2.png';
import visualDesign from '../../../assets/images/mnyVisualDesign.jpg';
import test from '../../../assets/images/UT1.png';
import mnyWireframe from '../../../assets/images/mnyWireframeGif.gif';
import mnyVideo from '../../../assets/images/mnyPrototype.gif';
import mnyLoginFlow from '../../../assets/images/mnyLoginFlow.png';
import mnyProgressFlow from '../../../assets/images/mnyProgressFlow.png';
import mnyCourseFlow from '../../../assets/images/mnyCourseFlow.png';
import mnyHiFi from '../../../assets/images/mnyHiFi.png';
import finalDesign from '../../../assets/images/mnyFinalDesign.jpg'
import emoji1 from '../../../assets/images/emoji1.png';
import emoji2 from '../../../assets/images/emoji2.png';
import emoji3 from '../../../assets/images/mnyemoji1.png';
import emoji4 from '../../../assets/images/mnyemoji2.png';
import emoji13 from '../../../assets/images/emoji13.png';
import UTMemoji from '../../../assets/images/UTMemoji.png';
import UT2 from '../../../assets/images/UT2.png';
import UT3 from '../../../assets/images/UT3.png';
import statistics from '../../../assets/images/statistics.png';
import Carousel from '../../../components/carousel/carousel';
import { HeadingCard, OverviewCard, BulletPointsCard, EmojiCard, ResearchCard, UserCommentsCard, 
  PictureCard, PrototypeCard, TextBlock, PictureCardSix, PictureCardSeven, FinalDesignCard, PictureCardFive, PictureCardThree, ImageCard } from '../../../components/cards/portfolioCards/portfolioCards';
import style from './mny.module.scss';
import ReactGA from 'react-ga';

export default function MNY() {
  const [screenSize, setScreenSize] = React.useState(null);
  ReactGA.pageview(window.location.pathname + window.location.search);

  React.useEffect(()=> {
    checkIsMobile();
    window.scrollTo({top: 0})
  },[]);
  
  const solutions =["Mobile app design", "Branding & visual design"];
  const timeline = ["80 hours"];
  const role = ["UI design", "UX design", "UX research"];
  const team =["Kyra Berman-Gestring", "Alice Roman, Co-Designer"]
  const concerns = ["Feeling like they had no one to learn from", "Industry experts don't reflect user identity/experience", "Industry gatekeeping by class/language", "Nuances of queer financial issues", "Overall unrelatability"]
  const userComments = [
    {
      emojiImg: emoji3,
      emojiAltText: "", 
      text: "I’m not expecting anyone to do it for me, I just don’t know where to start.", 
      name: "Participant ST"
    },
    {
      emojiImg: emoji13,
      emojiAltText: "", 
      text: "Financial literacy is gate kept by the wealthy.", 
      name: "Participant LM"
    },
    {
      emojiImg: emoji4,
      emojiAltText: "", 
      text: "I already feel othered by spaces like that, they are so alien to me, and I don’t want to feel judged on top of that.", 
      name: "Participant KB"
    }
  ];

  const checkIsMobile = () => {
    setScreenSize(window.innerWidth);
  }

  window.addEventListener('resize', checkIsMobile)

  const scollToRef = React.useRef();
  return (
    <main className={style.mnyContainer}>
      <HeadingCard lineBreak={screenSize <= 550 } isMobile={screenSize <= 550 ? true : false} imgClassName={style.headingPic} className={style.headingUl} heading="MNY" subheading="Mobile App Design" headerImg={mny} imgAltText="" list={["UX Research","System Design", "UI/UX Design", "Brand Identity"]} link="https://www.figma.com/proto/B43HoPykuXbu9Ul27Sz77T/MNY-App---Working-file?page-id=0%3A1&node-id=168%3A850&viewport=-3406%2C3192%2C1&scaling=scale-down&starting-point-node-id=168%3A850" />
      <OverviewCard ref={scollToRef} text="MNY is a education-forward app that empowers users to take control of their financial goals through a rewarding and accessible learning platform. Designed specifically to support LGBTQ+ community, this app offers nuanced content and fosters community building through live events and groups that serve queer folks but can benefit all. This project was developed as part of Avocademy’s 22’ Designathon and I worked in collaboration with fellow designer, Alice Roman."/>
      <div className={style.emojiCardsWrapper}>
        <EmojiCard title="The problem" className={style.smallerEmoji} emojiImg={emoji2} text="Avocademy’s Designathon prompted designers to address: How can we create an accessible tool that fosters financial literacy?" />    
        <EmojiCard title="The solution" className={style.offsetEmoji} emojiImg={emoji1} text="Inclusive and representative mobile app that empowers LGBTQ+ users to build financial literacy through life-long learning in community with others." />    
      </div>
      <BulletPointsCard screenSize={screenSize}  solutions={solutions} timeline={timeline} role={role} team={team}/>
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Project Background" 
        title="Socio-economic barriers to financial literacy"
        text={<><p className={style.lineHeight}>Creating a tool to improve financial literacy was the overarching directive for this design challenge. When meeting with my design partner, we wanted to ground our tool in our own lived experience. As two queer women, we were familiar with tools that supported women’s financial literacy but none that served the nuanced needs of the LGBTQ+ community. We used this shared experience to kickstart our design process.</p>
            </>} 
        pictureCardImg={projectBackground}
        pictureCardAltText=""
        imgClassName={style.mobileSmImg}
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Contextual Research" 
        title="LGBTQ+ wealth gap"
        text={<><p className={style.lineHeight}>We needed to discover if our assumptions were true, and learn more about current socio-economic trends.</p>
                <p className={style.lineHeight}>According to <a className={style.underline} target="_blank" rel="noreferrer" href='https://lgbtq-economics.org/issues/wealth-gap'>LGBTQ Economics</a>, queer people statistically earn less, have less savings/retirement, face higher rates of discrimination when taking loans, have higher interest rates and less legal workplace protections.</p>
                <p className={style.lineHeight}>All of this greatly impact their financial wellness. Millennial and Gen Z queer people report higher rates of financial concerns and need for support.</p>
                <p className={style.lineHeight}>Our research confirmed our assumptions were true. As statistics showed that younger queer folks have more need for financial support, we decided to focus on this group as our target audience. From here, we wanted to speak directly with users to learn first-hand how their queer identity impacts their financial literacy.</p>
            </>} 
        pictureCardImg={statistics}
        pictureCardAltText=""
        imgClassName={style.mobileSmImg}
        flipped={screenSize >= 800 ? true : false }
      />
      <hr className={style.lineBreak}/>
      <UserCommentsCard className={style.userComments} userComments={userComments}/>
      <hr className={style.lineBreak}/>
      <ResearchCard
        heading="User Research"
        title="1:1 interviews show nuanced impact of identity & money"
        text={<>
                <p className={style.lineHeight}>We conducted 6 1:1 interviews with LGBTQ+ Gen Z and Millennial users, to better understand their relationship to financial literacy and how they viewed their queerness relating to their financial wellness.</p>
                <p className={style.lineHeight}>We identified core concerns and barriers users felt to improving their financial literacy. Our assumption that their queer identity factors into this was confirmed, as users reported nuanced issues with their family of origin, or lack of representation impacting their ability to learn about financial wellness.</p>
              </>}
        researchImg={statistics}
        listItems={concerns}
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Competitive Analysis" 
        title="Lacking of representation in competing products"
        text={<><p className={style.lineHeight}>As millennial and Gen Z users expressed a preference for apps and wanting to engaging in learning, we decided to focus on creating a mobile app design focused on community-building through financial education.</p>
                <p className={style.lineHeight}>I  built a user persona and conducted a competitive analysis of other financial literacy tools who’s target users are marginalized groups.</p>
                <p className={style.lineHeight}>Through this process, common issues arose which informed the priorities, structure and tone of our design.</p>
            </>} 
        pictureCardImg={analysis}
        pictureCardAltText=""
        //imgClassName={style.mobileSmImg}
        wider
        className={style.competitiveAnalysisCard}
        flipped={screenSize >= 1050 ? true : false }
      />
      <hr className={style.lineBreak}/>
      <TextBlock heading="Define the Product" className={style.reducePad} text="Affirming mobile app for Gen-Z & Millennial users to learn in community & bridge LGBTQ+ wealth gap." />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Design" 
        title="Empathetic design"
        text={<><p className={style.lineHeight}>As millennial and Gen Z users expressed a preference for apps and wanting to engaging in learning, we decided to focus on creating a mobile app design focused on community-building through financial education.</p>
                <p className={style.lineHeight}>Creating an affinity map from common concerns that arose in our user interviews, I identified patterns and prioritized issues. From this we discerned that <b className={style.underline}>focusing on affirming education, representational experts, and community building were top priorities.</b></p>
            </>} 
        pictureCardImg={design}
        pictureCardAltText=""
        //imgClassName={style.mobileSmImg}
        flipped={screenSize >= 800 ? true : false }
      />
      <PictureCardThree
        text={<><p className={style.lineHeight}>We built a user persona representative of our target audience to personalize our product throughout development.</p>
                <p className={style.lineHeight}>To prepare an intuitive user experience, I mapped out user flows and task flows for the learning experience. This allowed us to anticipate user needs and actions and build a product that flowed in accordance with user thinking.</p>
                <p className={style.lineHeight}>As a UX designer, I always want to design with empathy for users, but that is even more important in this product as <b className={style.underline}>user’s expressed feeling like existing tools weren’t made with them in mind.</b></p>
            </>} 
        pictureCardImg={userFlow1}
        pictureCardImg2={userFlow2}
        pictureCardAltText2=""
        pictureCardAltText=""
        //imgClassName={style.mobileSmImg}
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Wireframes"
        title="Structure to create change"
        text={<><p className={style.lineHeight}>Informed by research we iterated on low, mid and high fidelity wireframes. I lead the layout design and refinement aspect of this project.</p><p className={style.lineHeight}>We focused on designing the frames for 3 main flows: login flow, complete course-in-progress flow, and review learning & save new course flow. These frames give a wide view of the capacity of the product and give clear insight into the usability of the product.</p><p className={style.lineHeight}>With revisions and iterations to improve the flow, a refined product was born.</p></>} 
        pictureCardImg={mnyWireframe}
        pictureCardAltText=""
        imgClassName={style.incrImage}
      />
      <ImageCard
        // heading="Login Flow"
        img={mnyLoginFlow}
        altText=""
      />
      <ImageCard
        // heading="Complete Course in Progress Flow"
        img={mnyProgressFlow}
        altText=""
      />
      <ImageCard
      // heading="Review Learning & Save New Course Flow"
      img={mnyCourseFlow}
      altText=""
      />
      <ImageCard
      // heading="Select Hi-fi wireframes"
      img={mnyHiFi}
      altText=""
      />
      <hr className={style.lineBreak}/>
      <PictureCard
        heading="Brand Identity"
        title="Identity & representation" 
        text={<>
          <p className={style.lineHeight}>Understanding that our users felt that the industry and experts are inaccessible and don't reflect their lived experiences, we focused on creating a strong visual brand that reflected and welcomed our target users. We began here because user’s expressed not being represented in the products, and wanted our product’s branding to match the ethos of being approachable, fun and pro-diversity.</p>
          <b className={` ${style.lineHeight} ${style.underline}`}>We built the visual brand strategy by focusing on:</b>
                <ul className={`${style.lineHeight} ${style.researchUl}`}>
                  <li>Logo design</li>
                  <li>Mood board creation</li>
                  <li>Sketching</li>
                  <li>Color scheme development</li>
                </ul>
          <p className={style.lineHeight}>Through iteration on a few mood boards, we choose a visual design that was a modern, fresh take on the rainbow and prioritized diversity through photographs and fun icons.</p>
          <p className={style.lineHeight}>We built a UI Kit to ensure consistency throughout our collaboration.</p>
        </>}
        pictureCardImg={visualDesign}
        pictureCardAltText=""
        imgClassName={style.incrImage}
        flipped={screenSize >= 800 ? true : false }
      />
      <hr className={style.lineBreak}/>
      <PrototypeCard         
        flipped={screenSize >= 800 ? true : false }
        className={style.row} imgClassName={style.protoImg} heading="Prototype" title="Interaction is key" prototypeImg={mnyVideo} prototypeAltText="" text="Using the high-fidelity wireframes, we built a prototype focusing on completing a course, adding a badge and browsing coursework. This prototype included creating a design system for consistency and ensuring usability for interactive components." link="https://www.figma.com/proto/B43HoPykuXbu9Ul27Sz77T/MNY-App---Working-file?page-id=0%3A1&node-id=168%3A850&viewport=-3406%2C3192%2C1&scaling=scale-down&starting-point-node-id=168%3A850"/>
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="User Testing"
        title="Improve from 80% to 100% user success!"
        text={<>
                <p className={style.lineHeight}>I then conducted usability testing from 5 participant tests, Users were observed  completing 6 tasks:</p>
                <p className={style.marginZero}>Task 1: Login</p>
                <p className={style.marginZero}>Task 2: Resume Ethical Investing 101 Course</p>
                <p className={style.marginZero}>Task 3: Complete “Ethical Investing 101” course</p>
                <p className={style.marginZero}>Task 4: Add Badge to your page</p>
                <p className={style.marginZero}>Task 5: Save “Setting Financial Goals” course</p>
                <p className={style.marginZero}>Task 6: Review Completed Courses</p>
                <p>I observed common pain points and successes. Synthesizing these insights, I made further iterations to improve the visual and information hierarchy and increase ease of use.</p>
              </>}
        pictureCardImg={test}
        pictureCardAltText=""
        flipped
      />
      <PictureCardFive
        text={<div>
                <b>Task 3 Iteration</b>
                <p>The video lesson page created confusion during usability testing and <b>we worked to improve the visual hierarchy and clarity in revising our design</b> by:</p>
                <ul>
                  <li>deemphasizing the course title to allow more focus on lesson</li>
                  <li>labeling the video and duration to improve clarity and provide user more information about the commitment needed for lesson</li>
                  <li>reducing clutter by redesigning the notes feature</li>
                  <li>improving actionability by refining the secondary button color</li>
                </ul>
              </div>}
        emoji={UTMemoji}
        emojiAlt=""
        quote="So is the lesson a video? … I'm not sure if this is the name of the lesson or the course."
        participant="-Participant XT"
        pictureCardImg={UT2}
        pictureCardImgAltText=""
      />
      <PictureCardSix
        pictureCardImg={UT3}
        pictureCardAltText=""
        heading={<p className={style.bold}>Task 4 Iteration</p>}
        cardText={<div className={style.shadowContainer}>
                    <p className={`${style.bold} ${style.marginZero}`}>Observation:</p>
                    <p className={style.removeTop}>Users confused around what action to take on this page.</p>
                    <p className={`${style.bold} ${style.marginZero}`}>Feedback:</p>
                    <p className={style.removeTop}>Users not all familiar with the construction book design and found it busy.</p>
                    <p className={`${style.bold} ${style.marginZero}`}>Re-design considerations:</p>
                    <ul className={style.removeTop}>
                      <li>How can we make CTA clearer?</li>  
                      <li>How can we reduce time spent on task?</li>
                    </ul>
                  </div>}
        text={<div style={{marginTop: "2em"}}>
                <p>Another point of confusion for users was adding their badge to their profile when they completed the course. During testing, we observed that users spent more time on this page and asked us for more guidance on what to do. Users also were not all familiar with the construction book design and that design reference didn’t support our goal.</p>
                <b className={style.marginZero}>We addressed these issues by:</b>
                <ul className={style.removeTop}>
                  <li>re-ordering the text describing the CTA</li>
                  <li>adding a pointer icon to emphasize click CTA</li>
                  <li>using a spiral notebook design in place of construction book</li>
                </ul>
                <hr className={style.lineBreak2}/>

              </div>}
      />
      <PictureCardSeven
        text={<div>
                <p>We then tested the success of our design iterations, conducting usability testing with 8 participants.</p>
                <b>From our redesign we saw overwhelming improvements:</b>
                <ul className={style.leftPadding}>
                  <li className={style.bold}>100% of users successfully completed all 6 testing requirements.</li>
                  <li className={style.bold}>Users were able to complete task 4 with 2x increased speed with new design.</li>
                </ul>
              </div>}
        participant="- Participant LM"
        quote="Financial things usually feel so yucky, but this tool feels more accessible and in line with my identity and morals."
        emoji={emoji13}
        emojiAlt=""
      />
      <hr className={style.lineBreak} ref={scollToRef}/>
      <FinalDesignCard 
        finalDesignImg={finalDesign} offsetImg={true} finalDesignAltText="" 
        text={<p className={style.lineHeight}>After improving visual hierarchy and finalizing the design, 95% of users reported eagerness to use the product! 90% of users surveyed reported the branding to be welcoming and approachable, and 92% were able to successfully complete all testing tasks.</p>}
        heading="Results to be proud of"
      />
      <div className={style.emojiCardsWrapper}>
        <EmojiCard title="Next steps" className={style.offsetEmoji}  emojiImg={emoji1} text={<p className={style.lineHeight}>To further improve this product, I would develop the groups pages. Money can be a taboo topic and can cause isolation, which I believe contributes to the gap in financial literacy. <b>MNY fosters community through group-learning and I think further developing the groups feature is important, as it distinguishes the product from other financial literacy learning tools.</b></p>}/>    
        <EmojiCard title="In reflection" className={style.smallerEmoji} emojiImg={emoji2} text={<p className={style.lineHeight}><b>Working alongside another designer was a wonderful opportunity to learn from one another and foster a supportive environment to reach a shared goal.</b> I really enjoyed the inspiration that was born bouncing ideas off one another! From research, I learned users really want a product like this and it felt affirming to design a tool that would help support so many to reach their goals.</p>}/>
      </div>

      {/* make mobile carousel later */}
      {screenSize > 600 ? 
            <>
             <hr className={style.lineBreak}/>
              <Carousel id="mny" /> 
            </> :
      <div></div>}

      {/* <hr className={style.lineBreak}/>
      <Carousel id="mny" /> */}
    </main>
  )
}
