import React from 'react';
import { Link } from 'react-router-dom';

import mny from '../../assets/images/MNY-header.png';
import campus from '../../assets/images/CampusDiveHome.png';
import nbnb from '../../assets/images/nbnbHeader.png';
import google from '../../assets/images/googleHeaderMobile.png';
import fff from '../../assets/images/FFF_Header_Small.png';
import style from './carousel.module.scss';
import { LeftArrowButton, RightArrowButton } from '../buttons/buttons';

const cards = [
  {
    'id': 'campus',
    'title': 'Supporting the college decision',
    'subTitle': 'B2C Edtech - Responsive Web Redesign',
    'link': '/CampusDive',
    'img': campus
  },
  {
    'id': 'nbnb',
    'title': 'Inclusive social golf community',
    'subTitle': 'Sports Social - Native Mobile App',
    'link': '/NBNB',
    'img': nbnb
  },
  {
    'id': 'mny',
    'title': 'LGBTQ+ financial literacy',
    'subTitle': 'Fintech - Mobile Product Design',
    'link': '/mny',
    'img': mny
  },
  {
    'id': 'fff',
    'title': 'Feminist movie search',
    'subTitle': 'Entertainment - UI/UX Design',
    'link': '/FeministFilmFinder',
    'img': fff
  },
  {
    'id': 'google',
    'title': "Improve teachers’ experience",
    'subTitle': 'Edtech - New Feature Design',
    'link': '/GoogleClassroom',
    'img': google
  }
]


function Carousel({ id }) {
  const [position, setPosition] = React.useState(0);
  const [position2, setPosition2] = React.useState(1)

  const cardsToView = cards.filter(i=> i.id !== id);

  const handleLeftClick = (e) => {
    console.log('positioon1', position)
    console.log('position2', position2)
    if(position === 0){
      setPosition(cardsToView.length - 1)
      setPosition2(position2 - 1)
    } else if(position2 === 0){
      setPosition2(cardsToView.length - 1)
      setPosition(position - 1)
    } else {
      setPosition(position - 1)
      setPosition2(position2 - 1)
    }
  }

  const handleRightClick = (e) => {
    if(position2 === cardsToView.length-1){
      setPosition2(0)
      setPosition(position + 1)
    } else if(position === cardsToView.length-1){
      setPosition(0)
      setPosition2(position2 + 1)
    } else {
      setPosition(position + 1)
      setPosition2(position2 + 1)
    }
  }

  return (
    <section className={style.carouselWrapper}>
      <h2 className={style.headingTwo}>View More Work</h2>
      <div className={style.carousel} >
        <LeftArrowButton onClick={handleLeftClick}/>

        <Link to={cardsToView[position].link} key={cardsToView[position].id} className={style.card}>
          <p className={style.title}>{cardsToView[position].title}</p>
          <p className={style.subTitle}>{cardsToView[position].subTitle}</p>
          <div className={style.imageContainer}>
            <img src={cardsToView[position].img} alt='' className={`${style.image} ${cardsToView[position].id === 'campus' ? style.campus : null} `} />
          </div>        
        </Link>
        <Link to={cardsToView[position2].link}  key={cardsToView[position2].id} className={style.card}>
          <p className={style.title}>{cardsToView[position2].title}</p>
          <p className={style.subTitle}>{cardsToView[position2].subTitle}</p>
          <div className={style.imageContainer}>
            <img src={cardsToView[position2].img} alt='' className={`${style.image} ${cardsToView[position2].id === 'campus' ? style.campus : null} `} />
          </div>
        </Link>         
  
        <RightArrowButton onClick={handleRightClick} />
      </div>
    </section>
  )
}

export default Carousel