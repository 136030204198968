import React from 'react';
import kyra2 from '../../../assets/images/3polaroid.png';

import style from './aboutMeHobbies.module.scss';

const AboutMeHobbies = () => {
  return (
    
    <section className={style.cardContainer}>
        <article>
            <h2 className={style.heading3}>Offline you can find me...</h2>
            <ul className={style.aboutList}>
                <li><span className={style.spanTitle}>🧶 CREATING</span><span className={style.spanText}>weaving, sewing, dyeing, tufting & more</span></li>
                <li><span className={style.spanTitle}>✈ EXPLORING</span><span className={style.spanText}>visited 18 countries & lived in 5 states</span></li>
                <li><span className={style.spanTitle}>📙 LEARNING</span><span className={style.spanText}>currently studying Spanish & Ceramics</span></li>
                <li><span className={style.spanTitle}>🌱 NURTURING</span><span className={style.spanText}>tending to a growing collection of plants</span></li>
            </ul>
        </article>
        <div className={style.aboutCoverImgWrapper}>
        <img className={style.aboutCoverImg} src={kyra2} alt="Three Polaroids of Kyra, One in a garden, one at a restaurant, and one at Chichen Itza"/>
        </div>
    </section>
  )
}

export default AboutMeHobbies