import React from 'react';
import projectBackground from '../../../assets/images/googleProjectBackground.jpg';
import googleResearch from '../../../assets/images/googleResearch.png';
import discover from '../../../assets/images/GoogleDiscover.png';
import researchOne from '../../../assets/images/googleResearch1.png';
import researchTwo from '../../../assets/images/googleResearch2.png';
import researchThree from '../../../assets/images/googleResearch3.png';
import competitiveAnalysis from '../../../assets/images/googleCompetitiveAnalysis.jpg';
import userFlow1 from '../../../assets/images/googleUserFlow1.png';
import userFlow2 from '../../../assets/images/googleUserFlow2.png';
import integrated1 from '../../../assets/images/googleIntegratedDesign1.png';
import prototype from '../../../assets/images/googlePrototype.png';
import usability from '../../../assets/images/googleUsabilityTesting.png';
import emoji1 from '../../../assets/images/emoji1.png';
import emoji2 from '../../../assets/images/emoji2.png';
import emoji7 from '../../../assets/images/emoji7.png';
import emoji8 from '../../../assets/images/emoji8.png';
import emoji9 from '../../../assets/images/emoji9.png';
import googleAllVideo from '../../../assets/images/googleFinalDesign.gif';
import headerImg from '../../../assets/images/googleHeaderGif.gif';
import Carousel from '../../../components/carousel/carousel';
import { HeadingCard, OverviewCard, BulletPointsCard, EmojiCard, UserCommentsCard, 
  PictureCard, PictureCardLink, PictureCardTwo, PrototypeCard, FinalDesignCard, TextBlock, GridCard } from '../../../components/cards/portfolioCards/portfolioCards';
import style from './googleClassroom.module.scss';
import ReactGA from 'react-ga';

export default function GoogleClassroom() {
  const [screenSize, setScreenSize] = React.useState(null);
  ReactGA.pageview(window.location.pathname + window.location.search);

  React.useEffect(()=> {
    checkIsMobile();
    window.scrollTo({top: 0})
  },[]);
  
  const solutions =["User Research", "Heuristic design"];
  const timeline = ["40 hours"];
  const role = ["User Researcher", "UI Designer", "UX Designer"];
  const researchList = ["Survey", "1:1 Interviews", "Contextual research", "Industry research", "Developing a user persona"]
  const userComments = [
    {
      emojiImg: emoji7,
      emojiAltText: "", 
      text: "Students turn assignments in and then they sit in my review pile...I end up putting in the full score and then going into Aeries to excuse them.", 
      name: "Mrs. L, English Teacher"
    },
    {
      emojiImg: emoji8,
      emojiAltText: "", 
      text: "I put NA in Aeries and in Canva, I would like to be able to do that in Google Classroom.", 
      name: "Mrs. M, Spanish Teacher"
    },
    {
      emojiImg: emoji9,
      emojiAltText: "", 
      text: "It’s really confusing for kids, because their grade in Google Classroom isn’t accurate.", 
      name: "Mr. E, Business Teacher"
    }
  ];

  const checkIsMobile = () => {
    setScreenSize(window.innerWidth);
  }

  window.addEventListener('resize', checkIsMobile)

  const scollToRef = React.useRef();
  return (
    <main className={style.googleContainer}>
      <HeadingCard lineBreak={screenSize <= 670 } isMobile={screenSize < 670 ? true : false} imgClassName={style.headingImg} className={style.headingUl} className2={style.headingCard} heading="Google classroom" subheading="Add a feature" headerImg={headerImg} link="https://www.figma.com/proto/R0XYkuK1iblyqnHKw1psCr/G-Class?page-id=0%3A1&node-id=2%3A7&viewport=236%2C325%2C0.13&scaling=min-zoom&starting-point-node-id=2%3A7" list={["UX Research", "UX Design", "Heuristic Analysis", "Design Integration"]} imgAltText="White background with three apple monitors together showing the Google Classroom website" />
      <OverviewCard ref={scollToRef} text="Google Classroom is a powerful educational platform but not without its flaws. Many teachers have to navigate multiple platforms based on their school district and have to find workarounds to create the functionality they need. To improve teacher’s experience with Google Classroom, I added a feature allowing teachers to excuse students from assignments. This will improve the grading experience for teachers and will make the status of assignments clearer to students."/>
      <div className={style.emojiCardsWrapper}>
        <EmojiCard title="The problem" className={style.smallerEmoji} emojiImg={emoji2} text="How can we improve teacher’s experience of Google Classroom and support Google to remain a leader in edtech?" />    
        <EmojiCard title="The solution" className={style.offsetEmoji} emojiImg={emoji1} text="Expanding grading options improves the teacher experience by allowing them to easily excuse students from assignments." />    
      </div>
      <BulletPointsCard screenSize={screenSize} solutions={solutions} timeline={timeline} role={role}/>
      <hr className={style.lineBreak}/>
      <PictureCardLink 
        heading="Project Background" 
        title="A product for 10 million users"
        text={<><p className={style.lineHeight}>The shelter-in-place orders had a great impact on all industries, but as a new teacher in 2020 I saw firsthand the profound impact it had on schools. Classrooms and teachers had to quickly adapt to the new normal and Google Classroom was a tool many districts turned to. According to <span><a href="https://www.nytimes.com/2017/05/13/technology/google-education-chromebooks-schools.html">NY Times</a></span>, over 15 million schools in the US use Google Classroom, and I’ve worked at 3 of them! </p>
                <p className={style.lineHeight}>Google classroom made it possible to streamline the online learning experience for many teachers, but it is not without its flaws. Many classrooms share the same needs in terms of grading, setting deadlines, etc. but each classroom is unique and google classroom needs to support this customization.</p>
                <p className={style.lineHeight}>Beloved by many, hated by some - all teachers I know have an opinion about google classroom and are eager to tell you!</p>
                <b className={style.lineHeight}>Other companies have quickly developed competing products and it is important Google responds to users' needs to remain a leader in edtech.</b>
            </>} 
        pictureCardImg={projectBackground}
        pictureCardAltText="Google Classroom logo which has a gray background and a green icon with a yellow border and white icons of people's torso and head"
        imgClassName={style.imgLink}
        link="https://www.teachthought.com/education/google-apps-for-education/"
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Research" 
        title="What do teachers need?"
        text={<><p className={style.lineHeight}>In order to identify the specific pain points that teacher users experience when using google classroom,</p>
                <ul className={`${style.lineHeight} ${style.researchUl}`}>I started the project with multiple angles of research including:
                  {researchList.map(item => <li className={style.researchLi}>{item}</li>)}
                </ul>
                <p className={style.lineHeight}>I selected a diverse group of participants to ensure a well-rounded study group; diverse genders, years of experience, races, and subjects taught were represented.</p>
            </>} 
        pictureCardImg={googleResearch}
        pictureCardAltText=""
        className={style.reverse}
        imgClassName={style.mobileSmImg}
        flipped
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Discover Focus" 
        title='"I would like to be able to do that in Google Classroom..."'
        text={<><p className={style.lineHeight}>From the survey, I got a broad view of general issues teachers faced with Google classroom. This allowed me to better focus my line of questioning when conducting 1:1 interviews.</p>
                <p className={style.lineHeight}>From these conversations, suggestions of features, complaints, and comparisons with other products were shared. Some teachers had features they wanted particular to their classroom, other more common issues arose.</p>
             </>} 
        pictureCardImg={discover}
        pictureCardAltText=""
        imgClassName={style.mobileSmImg}
      />
      <hr className={style.lineBreak}/>
      <UserCommentsCard userComments={userComments}/>
      <hr className={style.lineBreak}/>
      <PictureCardTwo 
        heading="Affinity Map"
        title="2 features with big impact"
        text={<><p className={style.lineHeight}>From these common issues, I arranged them based on two criteria, 1: impact on user and 2: ease of implementation.</p>
                <b className={` ${style.lineHeight}`}>The two features that have the greatest impact and easiest implementation were the ability to excuse students from assignments and an extra credit functionality.</b>
              </>}
        pictureCardImg={researchOne}
        pictureCardImg2={researchTwo}
        pictureCardImg3={researchThree}
        pictureCardAltText=""
        pictureCardAltText2=""
        pictureCardAltText3=""
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Competitive Analysis"
        title="Everyone else is doing it"
        text={<><p className={style.lineHeight}>In my user interviews, teachers mentioned other platforms’ ability to complete certain tasks and it is important for Google Classroom to keep up with competitors and meet user needs. I then conducted industry research, to better understand other leading edtech platforms and their functionality. All other edtech platforms I looked into had the ability to excuse students from work, Google classroom did not.</p>
                <p className={style.lineHeight}>From this, I determined focusing on an “excuse” function would have the highest impact with greatest ease of implementation. Adding this feature would also bring Google Classroom more in line with industry standards and further secure its position as a leading ed tech platform.</p>
            </>} 
        pictureCardImg={competitiveAnalysis}
        pictureCardAltText=""
        wider={true}
        className={style.competitiveAnalysisCard}
      />
      <hr className={style.lineBreak}/>
      <TextBlock heading="Define the Problem" text="Teacher’s need to be able to easily excuse students from assignments." />
      <hr className={style.lineBreak}/>
      <GridCard 
        heading="Integrated Design"
        title="Teacher’s don’t have time to learn something new"
        text={<><p className={style.lineHeight}>To prepare an intuitive user experience, I conducted further competitive analysis to see how other platforms approached excusing students from work. This posed a small challenge as many of the other platforms required having a school district account.</p>
                <p className={style.lineHeight}>I then mapped out a user flow and task flow to best anticipate teacher needs and actions to complete this task.</p>
                <b className={style.lineHeight}>I focused on creating a flow that could be seamlessly integrated with the existing Google Classroom experience.</b>
              </>}
        text2={<><p className={`${style.lineHeight} ${style.researchUl} ${style.underline}`} >I focused on addressing the questions:</p>
                <ul className={`${style.lineHeight}`}>
                  <li className={`${style.researchLi}`}><b>Where should the new feature be located?</b></li>
                  <li className={`${style.researchLi}`}><b>Where should teachers see students excused from work?</b></li>
                  <li className={`${style.researchLi}`}><b>How should teachers take the “Excuse” Action?</b></li>
                </ul>
                <p className={style.lineHeight}>From this I learned many possible approaches to the issue and considered what would work most seamlessly with the existing Google Classroom interface.</p>
              </>}
        gridImg1={userFlow1}
        gridImg2={integrated1}
        gridImg3={userFlow2}
        gridImgAltText1=""
        gridImgAltText2=""
        gridImgAltText3=""
      />
      <hr className={style.lineBreak}/>
      <PrototypeCard prototypeImg={prototype} prototypeAltText="" heading="Prototype" title="Atomic design for an accessible experience"
        link="https://www.figma.com/proto/R0XYkuK1iblyqnHKw1psCr/G-Class?page-id=0%3A1&node-id=2%3A7&viewport=236%2C325%2C0.13&scaling=min-zoom&starting-point-node-id=2%3A7"
        text="I created high-fidelity wireframes for the prototype. For this project I completed more advanced prototyping with many micro-interactions to most accurately simulate the grading experience in google classroom. This was challenging as it required close observation and layering of components within one another to create the final prototype. I also made two paths to completing the task, as users could select from the drop down or they could use a keyboard shortcut. This is important to increase accessibility, as it not only provides choice but creates increased ease of use for those users who navigate the page using a keyboard."
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Usability Testing"
        title="100% users excused students from work"
        text={<><p className={style.lineHeight}>Using the high-fidelity wireframes, we built a prototype and conducted usability testing.</p>
                <p className={style.lineHeight}>I then conducted usability testing, asking users to complete 3 tasks. All usability testing went very smoothly with all reporting ease of use and clarity of design. No major pain points arose from the testing, so I thin made minimal iterations for the final product.</p>
                <ul className={style.lineHeight}>
                  <li>100% users able to excuse student from assignment.</li>
                  <li>90% identified how to excuse student on first attempt.</li>
                  <li>Decreased time needed to excuse student by 30 seconds-1 minute.</li>
                </ul>
            </>} 
        pictureCardImg={usability}
        pictureCardAltText=""
        wider
      />
      <hr className={style.lineBreak} ref={scollToRef}/>
      <FinalDesignCard 
        finalDesignImg={googleAllVideo}
        finalDesignAltText=""
        heading="Teachers' love excuses!"
        text={<p className={style.lineHeight}>Users found the added feature seamless and intuitive with the existing interface and were easily able to navigate the added feature. Many users expressed that this addition would have a great impact on their day-to-day experience with google classroom and would make their grading much more seamless and accurate. Teachers also expressed that this feature would help students as it would be less confusing for them, as they would know what assignments were “Missing” and what were “Excused.” </p>}
      />
      <div className={style.emojiCardsWrapper}>
        <EmojiCard title="Next steps" className={style.offsetEmoji} emojiImg={emoji1} text={<><b className={`${style.lineHeight}`}>To further improve this product, I would expand upon the feature from the “Grades” tab view.</b><span className={style.lineHeight}> I would like to add the ability to excuse many students from multiple assignments all from one screen. This would make mass grading more seamless for teachers. I would also further develop a way to type a letter into the grade showing ___/20 as another option for using the feature.</span></>}/>      
        <EmojiCard title="In reflection" className={style.smallerEmoji} emojiImg={emoji2} text={<span className={style.lineHeight}>I really enjoyed working in educational technology and think it is such important work with such great impact. <b>There is such a need for powerful, personalizable, and seamless education tools that can make such a big difference for teachers, students and parents.</b>  I also learned a great deal about prototyping through this project and really appreciated the high level of detail that can be achieved at this stage.</span>}/>    
      </div>
      {/* <hr className={style.lineBreak}/>
      <Carousel id="google" /> */}
      {/* make mobile carousel later */}
      {screenSize > 600 ? 
            <>
             <hr className={style.lineBreak}/>
              <Carousel id="google" /> 
            </> :
      <div></div>}
    </main>
  )
}
