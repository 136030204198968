import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/kbglogo.png';
import MobileNav from './navs/mobileNav/mobileNav';
import DesktopNav from './navs/desktopNav/desktopNav';
import { NavToggleButton } from '../buttons/buttons';
import { Link } from 'react-router-dom';
import style from './header.module.scss';

export default function Header() {
  const [isOpen, setIsOpen] =useState(false);
  const [isMobile, setIsMobile] =useState(true);

  const body = document.getElementsByTagName("body")[0];

  const checkIsMobile = () => {
    if(window.innerWidth <= 800){
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  const handleClick = () => { 
    setIsOpen(!isOpen);
    if(!isOpen){
      body.classList.add("hide");
    }
    if(isOpen){
      body.classList.remove("hide")
    }
  }
  //I think this could use useMemo or useCallback
  const closeDrawer = () => {
    setIsOpen(false);
    body.classList.remove("hide")
  }

  useEffect(()=> {
    checkIsMobile();
  },[])

  window.addEventListener('resize', checkIsMobile)


  //Anytime a page is rendered we want to set isOpen to false and remove "hide" class
  return (
    <div className={style.headerWrapper}>
      <header id="top" className={style.headerContainer}>
        <Link onClick={closeDrawer} to="/"><img src={logo} alt="KBG Design logo" className={style.logo} /></Link>
        {isMobile ? 
          <NavToggleButton className={style.navToggleButton} onClick={handleClick} isOpen={isOpen} /> : 
          <DesktopNav closeDrawer={closeDrawer}/>
        }
      </header>
      <MobileNav isOpen={isOpen} closeDrawer={closeDrawer} />
    </div>
  )
}
