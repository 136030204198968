import React from 'react';
import { Link } from 'react-router-dom';
import { CloseIcon, ExpandLessIcon, ExpandMoreIcon, MenuIcon, BeforeIcon, NextIcon } from '../icons/icons';
import style from './buttons.module.scss';

export function BlackButton({ text, className, ...props}) {
  return (
    <button className={`${className} ${style.blackButton}`} {...props}>
      {text}
    </button>
  )
}

export function BlackButtonLink({ text, className, link, ...props}) {
  return (
    <a className={`${className} ${style.blackButton}`} {...props} href={link} target="_blank" rel="noreferrer">
      {text}
    </a>
  )
}

export function BlackButtonInternalLink({ text, className, to, ...props}) {
  return (
    <Link className={`${className} ${style.blackButton}`} to={to} {...props}>
      {text}
    </Link>
  )
}
export function BlueButton({ text, className, ...props}) {
  return (
    <button className={`${className} ${style.blueButton}`} {...props}>
      {text}
    </button>
  )
}

export function BlueButtonLink({ text, className, link, ...props}) {
  return (
    <a className={`${className} ${style.blueButton}`} {...props} href={link} target="_blank" rel="noreferrer">
      {text}
    </a>
  )
}
export function BlueButtonInternalLink({ text, className, to, ...props}) {
  return (
    <Link className={`${className} ${style.blueButton}`} to={to} {...props}>
      {text}
    </Link>
  )
}

export function ToggleButton({ isOpen, className, text, ...props}) {
  return (
    <button className={`${className} ${style.button}`} {...props} >
        {text} { isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
    </button>
  )
}

export function NavToggleButton({ isOpen, className, content, ...props }) {
  return (
    <button className={`${className} ${style.button}`} {...props} >
        {content}
        { isOpen ? <CloseIcon /> : <MenuIcon /> }
    </button>
  )
}

export function LeftArrowButton({ className, ...props }) {
  return (
    <button className={`${className} ${style.iconButton}`} {...props} >
        <BeforeIcon />
    </button>
  )
}

export function RightArrowButton({ className, ...props }) {
  return (
    <button className={`${className} ${style.iconButton}`} {...props} >
        <NextIcon />
    </button>
  )
}