import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as ExpandLess } from '../../assets/icons/expandLess.svg';
import { ReactComponent as ExpandMore } from '../../assets/icons/expandMore.svg';
import { ReactComponent as LinkArrow } from '../../assets/icons/linkArrow.svg';
import { ReactComponent as Email } from '../../assets/icons/email.svg';
import { ReactComponent as File } from '../../assets/icons/file.svg';
import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as Next } from '../../assets/icons/next.svg';
import { ReactComponent as Before } from '../../assets/icons/before.svg';


export const BeforeIcon = props => <Before {...props} />;

export const CloseIcon = props => <Close {...props} />;

export const ExpandLessIcon = props => <ExpandLess {...props} />;

export const ExpandMoreIcon = props => <ExpandMore {...props} />;

export const EmailIcon = props => <Email {...props} />;

export const FileIcon = props => <File {...props} />;

export const LinkedinIcon = props => <Linkedin {...props} />;

export const LinkArrowIcon = props => <LinkArrow {...props} />;

export const MenuIcon = props => <Menu {...props} />;

export const NextIcon = props => <Next {...props} />;