import React from 'react';
import { BlueButton, BlueButtonLink } from '../../buttons/buttons';
import ImageGallery from 'react-image-gallery';
import asterisk from "../../../assets/images/asterisk.svg";
import style from './portfolioCards.module.scss';


export function HeadingCard({ lineBreak, imgClassName, className, className2, className3, heading, subheading, headerImg, imgAltText, list, link, isMobile, product = false, figma = false }) {
  return (
    <section className={`${className2} ${className3} ${style.cardheadingContainer}`}>
        {isMobile? null :<img className={`${imgClassName} ${style.projectCoverImg}`} src={headerImg} alt={imgAltText} /> }
        <div className={style.headingCardTextWrapper}>
          <h1 className={style.heading}>{heading}</h1>
          <h2 className={`${style.subheading}`}>{subheading}</h2>
          { lineBreak ?  <hr className={style.headingLineBreak}/> : null }
          <ul className={`${style.headerUl} ${className}`}>
            {list.map((item, i) => <li key={i} className={style.headerLi}>{item}</li>)}
          </ul>
          {isMobile ? <img className={`${imgClassName} ${style.projectCoverImg}`} src={headerImg} alt={imgAltText} /> : null }
          <div>
            <BlueButtonLink text={product ? "View Product" : figma ? "View Figma Case Study" : "Go to Prototype"} link={link}/>
          </div>
          <br/>
        </div>
    </section>
  )
}

export function ComingSoonIFrame(){
  return (
    <section className={style.comingSoonContainer2}>
      <img src={asterisk} alt="" className={style.asterisk} />
        <span>WEB VERSION UNDER CONSTRUCTION</span>
        <img src={asterisk} alt="" className={style.asterisk} />
        <span>IFRAME CASE STUDY BELOW</span>
        <img src={asterisk} alt="" className={style.asterisk} />
    </section>
  )
}

export const OverviewCard = React.forwardRef(( props, ref ) => {
  const handleClick = () => {
    ref.current.scrollIntoView({ behavior: "smooth"});
  }
  return (
    <section className={style.shadowCardContainer}>
      <h2 className={`${style.heading2} ${style.overviewHeading}`}>Project Overview</h2>
      <p className={`${style.overviewText}`}>{props.text}</p>
      <div className={style.buttonContainer}>
        {props.seeFigma ? 
          <BlueButtonLink text="View Figma Case Study" link={props.link}/> :
          <BlueButton text="Go to Final Project" onClick={handleClick}/> 
        }
      </div>
    </section>
  )
})

export function BulletPointsCard({ solutions, timeline, role, team, screenSize, className }) {
  const isMobile = screenSize < 800 ? true: false;
  return (
    <section className={`${className} ${style.bulletCardContainer}`}>
      <article className={style.cardArticle}>
        <h3 className={style.heading3}>Solution</h3>
        <ul className={style.cardUl}>
          {solutions.map(((item, i) => <li key={i} className={style.cardLi}>{item}</li>))}
        </ul>
      </article>
      <hr className={isMobile ? style.noDivider : style.divider} />
      <article className={style.cardArticle}>
        <h3 className={style.heading3}>Timeline</h3>
        <ul className={style.cardUl}>
          {timeline.map(((item, i) => <li key={i} className={style.cardLi}>{item}</li>))}
        </ul>
      </article>
      <hr className={isMobile ? style.noDivider : style.divider} />
      <article className={style.cardArticle}>
        <h3 className={style.heading3}>Role</h3>
        <ul className={style.cardUl}>
          {role.map(((item, i) => <li key={i} className={style.cardLi}>{item}</li>))}
        </ul>
      </article>
      {team ? 
      <>
      <hr className={isMobile ? style.noDivider : style.divider} />
        <article className={style.cardArticle}>
          <h3 className={style.heading3}>Team</h3>
          <ul className={style.cardUl}>
            {team.map(((item, i) => <li key={i} className={style.cardLi}>{item}</li>))}
          </ul>
        </article>
      </>
      : null}
    </section>
  )
}

export function EmojiCard({ title, text, emojiImg, emojiAltText, className }) {
  return (
    <section className={style.emojiCardContainer}>
      <div className={style.emojiHeadingContainer}>
        <h2 className={style.emojiHeading}>{title}</h2>
        <img className={`${style.emojiImg} ${className}`} src={emojiImg} alt={emojiAltText} />
      </div>
      <p className={style.emojiText}>{text}</p>
    </section>
  )
}

export function ResearchCard({ heading, title, text, listItems, className }) {
  return (
    <section className={`${style.cardContainer} ${className}`}>
     
          <div className={style.picCardText}>
            <h2 className={style.picHeading}>{heading}</h2>
            <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
            <div>{text}</div>
          </div>
          <section className={style.concernsContainer}>
            <h3 className={style.concernsHeader}>User concerns:</h3>
            <ul className={style.researchCardUl}>
              {listItems.map((item, i) => <li key={i} className={style.researchCardLi}>{item}</li>)}
            </ul>
          </section>
        
    </section>
  )
}

export function GridCard({ heading, text, text2, title, gridImg1, gridImg2, gridImg3, gridImgAltText1, gridImgAltText2, gridImgAltText3 }) {
  return (
    <section>
        <div className={style.textImgWrapper}>
          <div className={style.headingTextWrapper}>
            <h2 className={style.picHeading}>{heading}</h2>
            <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
            <div>{text}</div>
          </div>
          <div className={style.imageWrapper2}>
            <img loading="lazy" className={`${style.gridImg} ${style.image3}`} src={gridImg1} alt={gridImgAltText1} />
            <img loading="lazy" className={`${style.gridImg} ${style.image3}`} src={gridImg3} alt={gridImgAltText1} />
          </div>
        </div>

        <div className={style.textImgWrapper2}>
          <div className={style.text2Wrapper}>{text2}</div>
          <img loading="lazy" className={style.gridImg} src={gridImg2} alt={gridImgAltText2} />
        </div>
    </section>
  )
}

export function UserCommentsCard({ userComments, className }) {
  return (
    <section className={`${className} ${style.userCommentsCardContainer}`}>
      {userComments.map((comment, i) => {
        const { emojiImg, emojiAltText, text, name, movie } = comment;
        return(
        <article className={style.commentArticle} key={i}>
          <img loading="lazy" className={style.userCommentsImg} src={emojiImg} alt={emojiAltText} />
          <div className={style.commentTextWrapper}>
            <p className={style.userCommentsText}>"{text}"</p>
            <p className={style.name}>- {name}</p>
            {movie ? 
            <p className={style.movie}>{movie}</p>
            : null
            }
          </div>
          
        </article>)})}
    </section>
  )
}

export function CardOne({ heading, img, altText, p, ul, imgClassName }) {
  return (
    <section className={style.cardContainer}>
      <div className={style.cardOneText}>
        <h2 className={style.heading2}>{heading}</h2>
        {p}
        <ul>{ul.map((item, i) => <li key={i}>{item}</li>)}</ul>
      </div>
      <img loading="lazy" className={`${style.image} ${imgClassName}`} src={img} alt={altText} />
    </section>
  )
}

export function TextBlock({ heading, text, className }) {
  return(
    <section>
      <h2 className={style.picHeading}>{heading}</h2>
      <div className={`${className} ${style.textBlock}`}>
        <p>{text}</p>
      </div>
    </section>
  )
}

export function ImageCard({ heading, img, className, altText }) {
  return(
    <section>
      <h2 className={`${style.picHeading} ${className}`}>{heading}</h2>
      <img loading="lazy" className={style.imgCard} src={img} alt={altText} />
    </section>
  )
}

export function PictureCard({ heading, isMobile, title, text, pictureCardImg, pictureCardAltText, className, className2, wider, imgClassName, imgClassName2, flipped }) {
  return (
    <section className={`${style.cardContainer} ${className}`}>
      {flipped ? <img loading="lazy" className={`${wider ? style.widerImg : style.image} ${imgClassName} ${imgClassName2}`} src={pictureCardImg} alt={pictureCardAltText} /> : null }
          <div className={`${className2} ${style.picCardText}`}>
            <h2 className={style.picHeading}>{heading}</h2>
            <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
            <div>{text}</div>
          </div>
      {flipped ? null : <img loading="lazy" className={`${wider ? style.widerImg : style.image} ${imgClassName} ${imgClassName2}`} src={pictureCardImg} alt={pictureCardAltText} /> }
    </section>
  )
}

export function PictureCardPicText({ heading, isMobile, imgOneText, title, text, pictureCardImg, pictureCardAltText, className, className2, wider, imgClassName, imgClassName2, flipped }) {
  return (
    <section className={`${style.cardContainer} ${className}`}>
      {flipped ? 
        <div>
          <img loading="lazy" className={`${wider ? style.widerImg : style.image} ${imgClassName} ${imgClassName2}`} src={pictureCardImg} alt={pictureCardAltText} />
          <p className={style.rightSide}>{imgOneText}</p>
        </div> : null }
          
          <div className={`${className2} ${style.picCardText}`}>
            <h2 className={style.picHeading}>{heading}</h2>
            <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
            <div>{text}</div>
          </div>
      {flipped ? null : 
      <div>
        <img loading="lazy" className={`${wider ? style.widerImg : style.image} ${imgClassName} ${imgClassName2}`} src={pictureCardImg} alt={pictureCardAltText} />
        <p className={style.rightSide}>{imgOneText}</p>
      </div> }
    </section>
  )
}
export function PictureCardCarousel({ heading, isMobile, title, text, pictures, className, className2, wider, carouselClassName, flipped, id }) {
  const [mystate, setMystate] = React.useState("")

  const screenChanged = (evt, )=> {
    if(evt){
      setMystate(id)
    }
    
    if(mystate.length > 0){
     const scrollToEle = document.getElementById(mystate);
      let scrool = scrollToEle.getBoundingClientRect()
      window.scrollTo(0, scrool.y - 100)
     // scrollToEle.scrollIntoView()
    }

    if(!evt){
      setMystate("")
    }
  }
 

  return (
    <section id={id} className={`${style.cardContainer} ${className}`}>
      {flipped ? <ImageGallery additionalClass={carouselClassName} items={pictures} showPlayButton={false} showBullets={true} onScreenChange={screenChanged} /> : null }
          <div className={`${className2} ${style.picCardText}`}>
            <h2 className={style.picHeading}>{heading}</h2>
            <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
            <div>{text}</div>
          </div>
      {flipped ? null : <ImageGallery loading="lazy" additionalClass={carouselClassName} items={pictures} showPlayButton={false} showBullets={true} onScreenChange={screenChanged} /> }
    </section>
  )
}

export function PictureCardLink({ link, heading, title, text, pictureCardImg, pictureCardAltText, className, wider, imgClassName, imgClassName2, flipped }) {
  return (
    <section className={`${style.cardContainer} ${className}`}>
      {flipped ? <a target="_blank" rel="noreferrer" href={link} className={style.center}><img loading="lazy" className={`${style.imageL} ${imgClassName}`} src={pictureCardImg} alt={pictureCardAltText} /></a> : null }
          <div className={style.picCardText}>
            <h2 className={style.picHeading}>{heading}</h2>
            <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
            <div>{text}</div>
          </div>
      {flipped ? null : <a target="_blank" rel="noreferrer" href={link} className={style.center}><img loading="lazy" className={`${style.imageL} ${imgClassName}`}  src={pictureCardImg} alt={pictureCardAltText} /></a> }
    </section>
  )
}

export function PictureCardTwo({ heading, title, text, pictureCardImg, pictureCardImg2, pictureCardImg3, pictureCardAltText, pictureCardAltText2, pictureCardAltText3 }) {
  return (
    <section className={` ${style.cardTwoContainer}`}>
      <div className={style.picCardText2}>
        <h2 className={style.picHeading}>{heading}</h2>
        <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
        <div>{text}</div>
      </div>
      <img loading="lazy" className={`${style.image} ${style.image5}`} src={pictureCardImg} alt={pictureCardAltText} />
      <img loading="lazy" className={`${style.image} ${style.image2}`} src={pictureCardImg2} alt={pictureCardAltText2} />
      <img loading="lazy" className={`${style.image} ${style.image2}`} src={pictureCardImg3} alt={pictureCardAltText3} />
    </section>
  )
}

export function PictureCardThree({ heading, text, pictureCardImg, pictureCardImg2, pictureCardAltText, imgClassName, pictureCardAltText2, className, flipped }) {
  return (
    <section className={`${style.cardThreeContainer} ${className}`}>
      {flipped ? 
      <div className={`${imgClassName} ${style.imageWrapper}`}>
        <img loading="lazy" className={style.image6} src={pictureCardImg} alt={pictureCardAltText} />
        <img loading="lazy" className={style.image6} src={pictureCardImg2} alt={pictureCardAltText2} />
      </div>
      : null}

      <div className={style.textWrapper}>
        <h2 className={style.heading2}>{heading}</h2>
        <div>{text}</div>
      </div>

      {flipped ? null :
      <div className={`${imgClassName} ${style.imageWrapper}`}>
        <img loading="lazy" className={style.image6} src={pictureCardImg} alt={pictureCardAltText} />
        <img loading="lazy" className={style.image6} src={pictureCardImg2} alt={pictureCardAltText2} />
      </div>
      }
    </section>
  )
}

export function PictureCardFour({ heading,  title, text, pictureCardImg, pictureCardImg2, pictureCardAltText, className, imgContainerName, flipped }) {
  return (
    <section className={`${style.cardFourContainer} ${className}`}>
      {flipped ?
        <div className={style.picCardFourTextWrapper2}>
          <h2 className={style.picHeading}>{heading}</h2>
          <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
        <div className={style.picCardFourText}>{text}</div>
      </div>
      : null}
      <div className={`${imgContainerName} ${style.image4Container}`}>
        <img loading="lazy" className={style.image4} src={pictureCardImg} alt={pictureCardAltText} />
        <img loading="lazy" className={style.image4} src={pictureCardImg2} alt={pictureCardAltText} />
      </div>
      {flipped ? null :
        <div className={style.picCardFourTextWrapper}>
          <h2 className={style.picHeading}>{heading}</h2>
          <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
          <div className={style.picCardFourText}>{text}</div>
        </div>
      }

    </section>
  )
}

export function PictureCardFourAndAHalf({ heading, imgOneText, imgTwoText, title, text, pictureCardImg, pictureCardImg2, pictureCardAltText, className, imgContainerName, flipped }) {
  return (
    <section className={`${style.cardFourContainer} ${className}`}>
      {flipped ?
        <div className={style.picCardFourTextWrapper2}>
          <h2 className={style.picHeading}>{heading}</h2>
          <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
        <div className={style.picCardFourText}>{text}</div>
      </div>
      : null}
      <div className={`${imgContainerName} ${style.image4Container}`}>
        <div>
          <img loading="lazy" className={style.image4} src={pictureCardImg} alt={pictureCardAltText} />
          <p className={style.rightSide}>{imgOneText}</p>
        </div>
        <div>
          <img loading="lazy" className={style.image4} src={pictureCardImg2} alt={pictureCardAltText} />
          <p className={style.rightSide}>{imgTwoText}</p>
        </div>
      </div>
      {flipped ? null :
        <div className={style.picCardFourTextWrapper}>
          <h2 className={style.picHeading}>{heading}</h2>
          <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
          <div className={style.picCardFourText}>{text}</div>
        </div>
      }

    </section>
  )
}

export function PictureCardFive({ emoji, emojiAlt, text, quote, participant, pictureCardImg, pictureCardAltText, }) {
  return (
    <section className={` ${style.cardFiveContainer}`}>
      <div className={style.picCardText5}>
        {text}
        <hr className={style.lineBreak}/>
        <div className={style.emojiContainer2}>
          <img loading="lazy" src={emoji} alt={emojiAlt} className={style.emojiImg2}/>
          <div className={style.emojiText2Wrapper}>
            <p>"{quote}"</p>
            <span className={style.name2}>{participant}</span>
          </div>
        </div>
      </div>
      <img loading="lazy" className={`${style.image} `} src={pictureCardImg} alt={pictureCardAltText} />
    </section>
  )
}

export function PictureCardSix({ heading, text, cardText, pictureCardImg, pictureCardAltText }) {
  return (
    <section className={` ${style.cardSixContainer}`}>
      <img loading="lazy" className={`${style.image} ${style.image7}`} src={pictureCardImg} alt={pictureCardAltText} />
      <div className={style.picCardText6}>
        {heading}
        {cardText}
        {text}
      </div>
    </section>
  )
}

export function PictureCardSeven({ text, participant, quote, emoji, emojiAlt }) {
  return (
    <section className={` ${style.cardSevenContainer}`}>
      {text}
      <hr className={style.lineBreak2}/>
      <div className={style.emojiContainer3}>
          <img loading="lazy" src={emoji} alt={emojiAlt} className={style.emojiImg2}/>
          <div className={style.emojiText2Wrapper}>
            <p>"{quote}"</p>
            <span className={style.name2}>{participant}</span>
          </div>
        </div>
    </section>
  )
}

export function PictureCardEight({ emoji, emojiAlt, text, quote, participant, pictureCardImg, imgClassName, pictureCardAltText, }) {
  return (
    <section className={` ${style.cardEightContainer}`}>
      <div className={style.picCard8Wrapper}>
        <img loading="lazy" className={`${style.image} ${imgClassName}`} src={pictureCardImg} alt={pictureCardAltText} />
        <hr className={style.lineBreak}/>
        <div className={style.emojiContainer2}>
          <img loading="lazy" src={emoji} alt={emojiAlt} className={style.emojiImg2}/>
          <div className={style.emojiText2Wrapper}>
            <p>{quote}</p>
            <span className={style.name2}>{participant}</span>
          </div>
        </div>
      </div>
      {text}
    </section>
  )
}

export function PrototypeCard({ heading, title, flipped, text, text2, prototypeImg, prototypeAltText, link, className, imgClassName }) {
  return (
    <section className={`${style.cardContainer} ${style.prototypeCardContainer} ${className}`}>
      {flipped ? <img loading="lazy" className={`${style.image} ${imgClassName}`} src={prototypeImg} alt={prototypeAltText} /> : null}
      <div className={style.prototypeTextWrapper}>
        <h2 className={style.picHeading}>{heading}</h2>
        <p className={`${style.picTitle} ${style.heading2}`}>{title}</p>
        <p className={style.lineHeight}>{text}</p>
        {text2 ? <p>{text2}</p> : null}
        <div className={style.buttonContainer}>
          <BlueButtonLink text="Go to Prototype" link={link}/>
        </div>
      </div>
      {flipped ? null : <img loading="lazy" className={`${style.image} ${imgClassName}`} src={prototypeImg} alt={prototypeAltText} />}    </section>
  )
}

export function FinalDesignCard({ heading, text, finalDesignImg, finalDesignAltText, offsetImg }) { 
  return (
    <div className={style.finalDesignCardContainer}>
      <section className={style.shadowCardContainer}>
        <h2 className={`${style.heading2} ${style.finalDesignHeading}`}>{heading}</h2>
        <div>{text}</div>
      </section>
      <img loading="lazy" className={`${style.finalImage} ${offsetImg ? style.imageOffset : ""}`} src={finalDesignImg} alt={finalDesignAltText} />
    </div>
  )
}