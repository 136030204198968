import React from 'react';
import { Link } from 'react-router-dom';
import style from './footerV2.module.scss';
import { EmailIcon, FileIcon, LinkedinIcon } from '../icons/icons';

export default function Footer() {

    const [isDesktop, setIsDesktop] = React.useState(null);

    const checkIsDesktop = () => {
    if(window.innerWidth >= 850){
        setIsDesktop(true);
    } else {
        setIsDesktop(false);
    }
    }

  React.useEffect(()=> {
    checkIsDesktop();
  },[isDesktop])

  window.addEventListener('resize', checkIsDesktop)

  
  return (
    <div className={style.footerContainer}>


    <footer className={style.footer}>
        <div className={style.footerWrapper}>
            <div className={style.basicInfoWrapper}>
                <p className={style.name}>Kyra Berman-Gestring {isDesktop ? <span className={style.pronouns2}>(she/her)</span> : <span></span> } </p>
                <p className={style.title}>UI / UX - Product Designer</p>
                {isDesktop ? <span></span> : <p className={style.pronouns}>(she/her)</p> }
            </div>

            <div className={style.navLinksWrapper}>
                <ul className={style.navLinks}>
                    <Link to="/resume"  className={style.iconText}>
                        <FileIcon className={style.icon} alt="Resume"/>
                    </Link>
                    <a href="https://www.linkedin.com/in/kyrabermangestring/" rel="noreferrer" target="_blank" className={style.iconText}>
                        <LinkedinIcon className={style.icon} alt="linkedin.com/in/kyrabermangestring"/>
                    </a>
                    <a href="mailto:kbermangestring@gmail.com" className={style.iconText}>
                        <EmailIcon className={style.icon} alt="kbermangestring@gmail.com"/>
                    </a>
                </ul>
                {isDesktop ? <div></div> : <div className={style.copyright}>© kbg design 2023</div>}
            </div>
        </div>
        {isDesktop ? <div>© kbg design 2023</div> : <div></div>}
    </footer>
    </div>
  )
}
