import React from 'react';
import projectBackground from '../../../assets/images/FFF_ProjectBackground.png';
import visualDesign from '../../../assets/images/FFF_VisualDesign.jpg';
import userResearch from '../../../assets/images/FFF_UserResearch.png';
import FFFPostit from '../../../assets/images/FFF_EmpatheticDesign.png';
import information from '../../../assets/images/FFF_Information.png';
import researchOne from '../../../assets/images/FFF_Research1.png';
import researchTwo from '../../../assets/images/FFF_Research2.png';
import wireframe from '../../../assets/images/FFF_Wireframes.jpg';
import testing from '../../../assets/images/FFF_Testing.jpg';
import prototype from '../../../assets/images/FFF_Prototype.gif';
import emoji1 from '../../../assets/images/emoji1.png';
import emoji2 from '../../../assets/images/emoji2.png';
import emoji14 from '../../../assets/images/emoji14.png';
import emoji15 from '../../../assets/images/emoji15.png';
import emoji16 from '../../../assets/images/emoji16.png';
import fffVideo from '../../../assets/images/FFF_FinalDesign.png';
import headerImgMobile from '../../../assets/images/FFF_Header_Small.png';
import Carousel from '../../../components/carousel/carousel';
import { HeadingCard, OverviewCard, BulletPointsCard, EmojiCard, UserCommentsCard,
  PictureCard, PictureCardLink, PictureCardFour, PrototypeCard, FinalDesignCard, TextBlock } from '../../../components/cards/portfolioCards/portfolioCards';
import style from './fff.module.scss';
import ReactGA from 'react-ga';

export default function FFF() {
  const [screenSize, setScreenSize] = React.useState(null);
  ReactGA.pageview(window.location.pathname + window.location.search);

  React.useEffect(()=> {
    checkIsMobile();
    window.scrollTo({top: 0})
  },[]);
  
  const solutions =["User Research", "Product Design", "Branding"];
  const timeline = ["80 hours"];
  const role = ["User Researcher", "Product Designer"];
  const team =["Kyra Berman-Gestring", "Founder/Engineer, Loren Mora"]
  const userComments = [
    {
      emojiImg: emoji16,
      emojiAltText: "", 
      text: "You immediately know by  the X and check mark if the movie passes the test or not.", 
      name: "Participant KM,",
      movie: "Favorite Movie: E.T. ✅"
    },
    {
      emojiImg: emoji15,
      emojiAltText: "", 
      text: "Easy-to-use tool that kicks other movie searches up a notch, so I can make informed decisions about what to watch.", 
      name: "Participant LA,",
      movie: "Favorite Movie: Jurassic Park ✅"
    },
    {
      emojiImg: emoji14,
      emojiAltText: "", 
      text: "I would like to see the lists as an item in the navigation menu so I don’t have to retrace my steps to get there.", 
      name: "Participant JM,",
      movie: "Favorite Movie: Braveheart ❌"
    }
  ];

  const checkIsMobile = () => {
    setScreenSize(window.innerWidth);
  }

  window.addEventListener('resize', checkIsMobile)

  const scollToRef = React.useRef();
  return (
    <main className={style.fffContainer}>
      <HeadingCard lineBreak={screenSize <= 670 } isMobile={screenSize <= 670 ? true : false} heading="Feminist film finder" imgClassName={style.headingImg} className={style.headingUl} className2={style.headingCard} subheading="Responsive Web Design" headerImg={headerImgMobile} imgAltText="White background with three apple monitors together showing the Google Classroom website" list={["Product Design", "UX Research", "Developer Collaboration", "Brand Identity"]} link="https://www.figma.com/proto/LErjNHfCY3RmPnPFcP4CjP/FFF?page-id=188%3A4019&node-id=188%3A4324&viewport=-1585%2C576%2C0.3&scaling=scale-down&starting-point-node-id=188%3A4324&show-proto-sidebar=1"/>
      <OverviewCard ref={scollToRef} text="Feminist Film Finder is a movie search platform that allows users to see if films pass the Bechdel test. This social platform also allows users to create and share curated lists of films, leave reviews and rankings. Designed for development, research and attention to accessibility were of priority to the client and critical to my collaborations with the software developer."/>
      <div className={style.emojiCardsWrapper}>
        <EmojiCard title="The problem" className={style.smallerEmoji} emojiImg={emoji2} text="Client and software developer need research-informed design solutions for users to browse, search, create lists and make a profile on a responsive website that shows users if movies pass the Bechdel test." />    
        <EmojiCard title="The solution" className={style.offsetEmoji} emojiImg={emoji1} text="Intuitive and aesthetic forward responsive web design that makes it quick and familiar for users to access site content and learn more about diversity and inclusion in movies. Designing intuitive experience for users to create and share lists of curated content." />    
      </div>
      <BulletPointsCard screenSize={799} solutions={solutions} timeline={timeline} role={role} team={team}/>
      <hr className={style.lineBreak}/>
      <PictureCardLink 
        heading="Project Background" 
        title="Diversity lacking in movies"
        text={<><p className={style.lineHeight}>Driven by a commitment to diversity and equity, the concept inspiring this project is to create a product that allows users to make informed decisions about what media they consume. The priority of the project at present is if a movie is “feminist” or not, measured by whether or not it passes the Bechdel test.</p>
                <p className={style.lineHeight}>In collaboration with a client and software developer, I was brought on to design the product for Feminist Film Finder. The client had a small team and foundational concept but needed support in the branding, information architecture and visual design.</p>
                <p className={style.lineHeight}>This project is a work-in-process as I will design the complete end-to-end user experience. For this case study, I am focusing on the start. This encompasses the branding, visual design, market and user research as well as mapping and prototyping for the search and add to list features.</p>
            </>} 
        pictureCardImg={projectBackground}
        pictureCardAltText=""
        imgClassName={style.imgLink}
        link="https://www.bitchmedia.org/post/for-every-woman-in-the-film-industry-there-are-five-men"
      />
      <hr className={style.lineBreak}/>
      <PictureCardFour 
        heading="Contextual Research"
        title="What is the Bechdel Test?" 
        text={<><p className={style.lineHeight}>To begin this project, I familiarized myself with the history and origins of the <a className={style.underline} target="_blank" rel="noreferrer" href='https://bechdeltest.com/'>Bechdel test</a>. The Bechdel test is a meager standard that has only three simple criteria that measures whether or not a film is feminist. This standard may seem easy to fulfill, or insufficient to determine whether or not a film is feminist but it is the most recent standard used and so many movies that come out today still do not pass.</p>
        <p className={style.lineHeight}>According to the Bechdel test database,</p>
        <b className={style.centered}>only 57% of movies pass the Bechdel test.</b></>} 
        pictureCardImg={researchOne}
        pictureCardImg2={researchTwo}
        pictureCardAltText=""
        //imgClassName={style.incrImage}
        className={style.research}
        flipped={screenSize <= 800 ? true : false }

      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="User Research" 
        title="Users care about equity...but don't know the Bechdel Test"
        text={<><p className={style.lineHeight}>To better focus the product, I conducted user research to better understand user’s needs and values with regard to diversity in movies.</p>
                <b className={`${style.lineHeight} ${style.underline}`}>My research comprised of:</b>
                <ul className={`${style.lineHeight} ${style.researchUl}`}>
                  <li>Survey</li>
                  <li>1:1 interviews</li>
                  <li>Competitive Analysis</li>
                  <li>Persona development</li>
                </ul>
                <p className={style.lineHeight}>In speaking with the research study participants, not all were familiar with the standard. When conducting market research, there are limited competitors that allow you to search for films against this criteria and none that allow you to curate content in a personalized profile.</p>
            </>} 
        pictureCardImg={userResearch}
        pictureCardAltText=""
        imgClassName={style.mobileSmImg}
      />
      <div className={style.textCardContainer}>
        <p>This lack of presence in the market and un-familiarity among participants required an innovative solution that made it accessible and easy to learn while being intuitive to navigate.</p>
      </div>
      <PictureCard
        text={<><p className={style.lineHeight}>From one-on-one interviews, I learned what users knew (or didn’t) about the Bechdel Test as well as gathered important information about what they valued when choosing a movie to watch and recommending a movie to others. I organized this information in an affinity map.</p>
                <p className={style.lineHeight}>I synthesized the findings from the survey, interviews and competitive analysis to direct product goals and develop a persona and edge cases that would help personalize the product throughout development.</p>
                <p className={style.lineHeight}><b>This research gave clarity to the user’s needs and allowed me to strategize what features would meet both user and stakeholder needs by clearly defining the product</b> and breaking the design into phases.</p>
            </>} 
        pictureCardImg={FFFPostit}
        pictureCardAltText=""
        imgClassName={style.mobileSmImg}
        flipped={screenSize >= 800 ? true : false }
      />
      <hr className={style.lineBreak}/> 
      <TextBlock heading="Define the Product" text="An accessible website that encourages learning & sharing about inclusivity in films." />
      <hr className={style.lineBreak}/> 
      <PictureCard 
        heading="Information Architecture & User Mapping"
        title="Living up to expectations"
        text={<><p className={style.lineHeight}>To prepare an intuitive user experience, I mapped out user flows and task flows for the login, movie search, browse and add to list tasks.</p>
                <p className={style.lineHeight}><b>This mapping allows me to anticipate user needs and actions and build a product that flows intuitively with user thinking.</b></p>
                <p className={style.lineHeight}>I also conducted a card-sorting activity and from the results of 6 participants, refined the structure and sitemap to make a product that meet’s users expectations.</p>
              </>} 
        pictureCardImg={information}
        pictureCardAltText=""
        className={style.information}
        flipped={screenSize >= 800 ? true : false }
      />
      <hr className={style.lineBreak}/> 
      <PictureCard 
        heading="Wireframes"
        title="Frame by frame"
        text={<><p className={style.lineHeight}>I visually mapped out the site through wireframing to refine the design and structure of the site. Integrating client and peer feedback, I iterated on low, mid and high fidelity wireframes.</p>
                <p className={style.lineHeight}>I focused on building a home page, browse page, movie search page, login screen, movie details screen, and list page for the first phase of the design haul.</p>
                <p className={style.lineHeight}>With revisions and iterations to improve the flow, we progressed into the next stage.</p>
              </>} 
        pictureCardImg={wireframe}
        pictureCardAltText=""
        className={style.wireframe}
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Branding & UI Design"
        title="Refine & elevate the visual design"
        text={<><p className={style.lineHeight}>By integrating the client’s brand goals and analyzing competitor research, I developed a visual brand identity that balanced approachability and the intellectual nature of the site. Understanding that many users may not be familiar with the test, I drew upon visuals and structures that were recognizable to increase user confidence and comprehension. I balanced the visual aesthetics between modern and retro design, drawing on classic movie industry aesthetics blended with modern streaming service structure.</p>
                <b className={`${style.lineHeight} ${style.underline}`}>I built the visual brand strategy focusing on:</b>
                <ul className={`${style.lineHeight} ${style.ul}`}>
                  <li><b>Logo design</b></li>
                  <li><b>Mood board creation</b></li>
                  <li><b>Sketching</b></li>
                  <li><b>Color scheme development</b></li>
                </ul>
                <b className={`${style.lineHeight} ${style.underline}`}>With consideration of client constraints including:</b>
                <ul className={`${style.lineHeight} ${style.ul}`}>
                  <li>drawing from existing API</li>
                  <li>focus on A11Y accessibility</li>
                  <li>desire for a modern intellectual yet approachable vibe</li>
                </ul>
                </>} 
        pictureCardImg={visualDesign}
        pictureCardAltText=""
        //imgClassName={style.incrImage}
        flipped={screenSize >= 800 ? true : false }
      />
      <hr className={style.lineBreak}/>
      <PrototypeCard prototypeImg={prototype} prototypeAltText="" 
        link="https://www.figma.com/proto/LErjNHfCY3RmPnPFcP4CjP/FFF?page-id=188%3A4019&node-id=188%3A4324&viewport=-1585%2C576%2C0.3&scaling=scale-down&starting-point-node-id=188%3A4324&show-proto-sidebar=1"
        text="Using Figma, I built a prototype, focusing on the adding movie to list flow from the high-fidelity wireframes. For the prototyping I focused on interactive components that allowed users to add movies to list, two search bars that have a hover state and an overlay that brings up the movie details. I also incorporated key commands to allow more accessibility in the prototype."
        heading="Prototype"
        title="Product in action"
      />
      <hr className={style.lineBreak}/>
      <PictureCard 
        heading="Usability Testing"
        title="100% success rate"
        text={<>
                <p className={style.lineHeight}>I then conducted usability testing and from 5 participant tests, I observed common pain points and successes. Users reported great ease and familiarity when using the product and appreciated the familiar structure of streaming service carousel design. Some confusion arose when users were asked to review their list. While all participants were able to complete the task, they suggested adding other navigation options to review lists.</p>
                <p className={style.lineHeight}>After synthesizing the feedback and data from testing, <b>I iterated on the navigation bar in design and prototype to improve usability.</b></p>
                <p className={style.lineHeight} style={{marginBottom: 0}}>After the redesign...</p>
                <ul className={`${style.lineHeight}${style.ul}`}>
                  <li><b>100% users sucessfully add movie to list.</b></li>
                  <li><b>95% users able to review list after flow.</b></li>
                  <li><b>90% used prototype key commands to complete task.</b></li>
                </ul>
              </>} 
        pictureCardImg={testing}
        pictureCardAltText=""
      />
      <hr className={style.lineBreak}/>
      <UserCommentsCard className={style.userComments} userComments={userComments}/>    
      <hr className={style.lineBreak} ref={scollToRef}/>
      <FinalDesignCard 
        finalDesignImg={fffVideo}
        finalDesignAltText=""
        heading="Finding feminist films made easy"
        text={<>
          <p className={style.lineHeight}>The familiar structure of the streaming service carousel made the learning curve of the Bechdel Test easy for users to achieve, as it balanced a common structure with a new feature. Users reported eagerness to learn more about diversity and equity in movies - <b>making the product a clear success at achieving it’s goal.</b> As the developer pushes the designs to production, I look forward to learning more from users as we expand on the features of the site.</p>
        </>}
      />
      <div className={style.emojiCardsWrapper}>
        <EmojiCard title="Next steps" className={style.offsetEmoji} emojiImg={emoji1} text={<p className={style.lineHeight}>I will continue to collaborate with the software engineer client in conducting research and designing screens for this site. <b>I will focus on the sign-up flow and user account features in my next round of research, design and testing.</b> As the site is being built, I will be actively involved in conversation and iteration to improve the communication and refine the design system for the site. We also will both conduct an accessibility audit and iterate to improve the accessibility of our product.</p>}/>      
        <EmojiCard title="In reflection" className={style.smallerEmoji} emojiImg={emoji2} text={<p className={style.lineHeight}>Our movie industry, although improving, still falls so short of the diversity we need. Working directly with a software engineer to create an accessible and informative product is a powerful experience as it allows me to participate directly in <b>using design and tech to make an educational and beautiful product for social good.</b> I learned a lot about labeling and communicating with a software engineer on this project and am excited to continue this collaboration.</p>} />    
      </div>
      {/* <hr className={style.lineBreak}/>
      <Carousel id="fff" /> */}
      {screenSize > 600 ? 
            <>
             <hr className={style.lineBreak}/>
              <Carousel id="fff" /> 
      </> :
      <div></div>}
    </main>
  )
}
