import React from 'react';
import ReactGA from 'react-ga';
import campus from '../../assets/images/CampusDiveHome.png';
import nbnbHeader from '../../assets/images/nbnbHeader.png';
import Hero from '../../components/heroV2/hero';
import AnimationProjectCard from '../../components/cards/homeCards/animationProjectCard';
import style from './homeV2.module.scss';


export default function Home() {
  const [isMobile, setIsMobile] = React.useState(false);
  ReactGA.pageview(window.location.pathname + window.location.search);

  const checkIsMobile = () => {
    if(window.innerWidth < 800){
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }
 
  React.useEffect(()=> {
    checkIsMobile();
    window.scrollTo({top: 0})
  },[])
  
  window.addEventListener('resize', checkIsMobile)

  return (
    <main className={style.homeContainer}>
      <Hero isMobile={isMobile} />
      <h2 className={style.heading2}>FEATURED PRODUCTS</h2>
      <AnimationProjectCard 
        heading3="Stategic UI for diverse representation & inclusion"
        heading4="UX / UI FEATURE DESIGN"
        heading4SubText="Mobile Native App"
        text="Strategic Visual Design for Inclusive Golf Social media app that wants an approachable brand for an ever-diversifying golf industry."
        animationText="INCLUSIVE  - APP - GOLF - SOCIAL  -"
        flipped={false}
        campus={false}
        headerImg={nbnbHeader}
        to="/NBNB"
        imgClassName={style.nbnbImg}
      />
      <AnimationProjectCard 
        heading3="Redesigning Intake for optimal Impact"
        heading4="UX / UI FEATURE DESIGN "
        heading4SubText="B2C Edtech, Web"
        text="Collaborating with key stakeholders to redesign intake process for improved user experience."
        animationText=" UI - DESIGN SYSTEM - SCALABLE - GROWTH - " //make the T transform 327
        flipped={true}
        headerImg={campus}
        to="/CampusDive"
        imgClassName={style.campusImg}
        campus={true}
      />
    </main>
  )
}
