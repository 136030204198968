import React from 'react';
import { Link } from 'react-router-dom';
import { BlueButtonInternalLink } from '../../buttons/buttons';
import style from './portfolioPageCard.module.scss';

export default function PortfolioPageCard({ heading3, heading4, heading4SubText, text, headerImg, className, imgClassName, to }) {
  
  return (
    <section className={`${style.cardContainer} `}>
      <Link className={`${style.imgContainer} `} to={to}>
        <img loading='lazy' className={`${style.projectCardImg} ${imgClassName}`} src={headerImg} alt="" />
      </Link>

      <div className={`${style.cardTextContainer} ${className}`}>
        <h3>{heading3}</h3>
        <h4>{heading4} <span>{heading4SubText}</span></h4>
        <hr className={style.lineBreak}/>
        <p className={style.text}>{text}</p>
        <BlueButtonInternalLink  
            text="Case Study"
            className={style.blackButton}
            to={to}
        /> 
      </div>
    </section>
  )
}
