import React from 'react';
import ReactGA from 'react-ga';
import { Routes, Route } from "react-router-dom";
import Header from './components/header/header';
import HomeV2 from './pages/home/homeV2';
import Resume from './pages/resume/resume';
import CampusDive from './pages/portfolio/cd/cdPreview';
import MNY from './pages/portfolio/MNY/mny';
import FFF from './pages/portfolio/fff/fff';
import GoogleClassroom from './pages/portfolio/GoogleClassroom/googleClassroom.js';
import NBNB from './pages/portfolio/NBNB/nbnb';
import PortfolioPage from './pages/portfolioPage/portfolioPage.js';
import Footer from './components/footer/footerV2';
import './App.css';
import AboutPage from './pages/about/aboutV2.js';

function App() {
  ReactGA.initialize('G-NRZW311QGL');

  // const resume = document.getElementById("resume");
  // console.log('reume?', resume)
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomeV2 />}/>    
        <Route path="portfolio" element={<PortfolioPage />}/>
        <Route path="about" element={<AboutPage />}/>
        <Route path="resume" element={<Resume />} />
        <Route path="NBNB" element={<NBNB />} />
        <Route path="CampusDive" element={<CampusDive />} />
        <Route path="MNY" element={<MNY />} />
        <Route path="FeministFilmFinder" element={<FFF />} />
        <Route path="GoogleClassroom" element={<GoogleClassroom />} />
        {/* <Route path="4ALL" element={<ForAll />} /> */}
      </Routes>
      <Footer />
    </> 
  );
}

export default App;
