import React from 'react';
import { Link } from 'react-router-dom';
import { BlueButtonInternalLink } from '../../buttons/buttons';
import style from './animationProjectCard.module.scss';

export default function AnimationProjectCard({ heading3, heading4, heading4SubText, text, animationText, flipped, headerImg, campus, imgClassName, to }) {
  
  return (
    <section className={`${style.cardContainer}  ${flipped ? style.flippedContainer : style.notFlippedContainer}`}>
      {/* IMG AND ANIMATION */}

      {campus ? 
          <div className={`${style.imgAndAnimationContainer} ${style.campusWrapper}`}>
            <Link to={to} className={style.campusImgWrapper}>
                <img className={`${style.projectCardImg2} ${imgClassName}`} src={headerImg} alt="" />
            </Link>
            <div className={style.animationContainer}>
              <div className={style.animatedTextWrapper}>
                    <p>
                    {/* make 9 deg for b2c */}
                    {animationText.split("").map((char, i) => (
                      <span key={char + i} style={{transform: `rotate(${i * 9}deg)` }}>{char}</span>
                    ))}
                  </p>                     
              </div>
            </div>

          </div>
            :
          <div className={`${style.imgAndAnimationContainer}`}>
            <div className={style.animationContainer}>
              <div className={style.animatedTextWrapper}>
                    <p>
                    {animationText.split("").map((char, i) => (
                      <span key={char + i} style={{transform: `rotate(${i * 10}deg)` }}>{char}</span>
                    ))}
                  </p>                     
              </div>
            </div>
              <Link to={to} className={style.nbnbImgWrapper}>
                <img className={`${style.projectCardImg} ${imgClassName}`} src={headerImg} alt="" />
              </Link>
          </div>
      }


{/* TEXT */}
      <div className={`${style.cardTextContainer} ${campus ? style.campusTextWrapper : ""}`}>
        <h3>{heading3}</h3>
        <h4>{heading4} <span>{heading4SubText}</span></h4>
        <hr className={style.lineBreak}/>
        <p className={style.text}>{text}</p>
        <BlueButtonInternalLink  
            text="Case Study"
            className={style.blackButton}
            to={to}
        />
      </div>
    </section>
  )
}
