import React from 'react';
import { BlueButtonInternalLink } from '../../buttons/buttons';
import kyra from '../../../assets/images/aboutGif.gif';
import bubble from '../../../assets/images/speechBubble.svg';
import style from './aboutMeHero.module.scss';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { LinkArrowIcon } from '../../icons/icons';


const AboutMeHero = ({ isMobile }) => {

    return (
  <>
  {isMobile ?
    <section className={style.container}>
        <div className={style.headingWrapper}>
            <p className={style.heading1}>Cultivating more empathy, ease and impact, one design at a time.</p>
            <div className={`${style.aboutCoverImgContainer}`} >
                <img className={style.aboutCoverImg} src={kyra} alt=""  />
                <img src={bubble} alt="" className={style.speechBubble}/>
            </div>
        </div>
        <nav className={style.navWrapper}>
            <ul className={style.nav}>
                <li className={style.listItem}><NavHashLink to="/about#contact" >Contact <LinkArrowIcon className={style.linkArrow} />  </NavHashLink></li>
                <li className={style.listItem}><Link to="/resume" >Resume <LinkArrowIcon className={style.linkArrow} /></Link></li>
                <li className={style.listItem}><NavHashLink to="/about#values" >Values <LinkArrowIcon className={style.linkArrow} /></NavHashLink></li>
            </ul>
        </nav>
        <div className={style.bioWrapper}>
            <h2>KYRA BERMAN-GESTRING</h2>
            <span className={style.pronoun}>(she/her)</span>
            <p>My journey as a designer began with a needle and thread. Since you are using a computer, yours did too. I began blending my love of function, form, and history as a designer weaving on a loom, the predecessor to the computer. Nowadays, I mostly just stick <span className={style.blueText}>weaving together streamlined solutions to complex problems</span> as a Product Designer, swapping the yarn for a computer.</p>
            <p>I’m a Product Designer (UI/UX) with a <span className={style.blueText}>passion for inclusivity</span> and accessibility. Technology is a powerful tool and I take my responsibility as a designer seriously. I dive deep into complex problems areas to <span className={style.blueText}>deliver data-informed design solutions</span> that respect the rich diversity of the human experience. </p>
            <div className={style.buttonWrapper}>
                <BlueButtonInternalLink className={style.button} text="View Portfolio" to="/portfolio" />
            </div>
        </div>
    </section> :

    //NOT MOBILE
    <section className={style.containerDT}>
        <div className={`${style.aboutCoverImgContainerDT}`} >
                <img className={style.aboutCoverImgDT} src={kyra} alt=""  />
                <img src={bubble} alt="" className={style.speechBubble}/>
        </div>
        <div className={style.bioWrapperDT}>
            <h2>KYRA BERMAN-GESTRING <span className={style.pronounDT}>(she/her)</span></h2>
            <div className={style.headingWrapperDT}>
                <p className={style.heading1DT}>Cultivating more empathy, ease and impact, one design at a time.</p>
            </div>  
            <p>My journey as a designer began with a needle and thread. Since you are using a computer, yours did too. I began blending my love of function, form, and history as a designer weaving on a loom, the predecessor to the computer. Nowadays, I mostly just stick <span className={style.blueTextDT}>weaving together streamlined solutions to complex problems</span> as a Product Designer, swapping the yarn for a computer.</p>
            <p>I’m a Product Designer (UI/UX) with a <span className={style.blueTextDT}>passion for inclusivity</span> and accessibility. Technology is a powerful tool and I take my responsibility as a designer seriously. I dive deep into complex problems areas to <span className={style.blueTextDT}>deliver data-informed design solutions</span> that respect the rich diversity of the human experience. </p>
            <div className={style.buttonWrapperDT}>
                <BlueButtonInternalLink className={style.buttonDT} text="View Portfolio" to="/portfolio" />
            </div>
        </div>
    </section>}
  </>
    
)}

export default AboutMeHero