import React from 'react';

import style from './aboutV2.module.scss';
import ReactGA from 'react-ga';
import AboutMeHero from '../../components/cards/aboutMe/aboutMeHero';
import AboutMeValues from '../../components/cards/aboutMe/aboutMeValues';
import AboutMeConnect from '../../components/cards/aboutMe/aboutMeConnect';
import AboutMeExperience from '../../components/cards/aboutMe/aboutMeExperience';
import AboutMeHobbies from '../../components/cards/aboutMe/aboutMeHobbies';

export default function AboutPage() {
  const [isMobile, setIsMobile] = React.useState(null);
  ReactGA.pageview(window.location.pathname + window.location.search, null, "about");

  const checkIsMobile = () => {
    if(window.innerWidth <= 800){
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  React.useEffect(()=> {
    checkIsMobile();
    window.scrollTo({top: 0})
  },[])

  window.addEventListener('resize', checkIsMobile)


  return (
    <main className={style.aboutContainer}>
      <AboutMeHero 
        hasImage={true}
        btn={true}
        to="/"
        isMobile={isMobile}
        heading={!isMobile}
        text="yo"
      />
      <AboutMeValues />
      <AboutMeConnect />
      <AboutMeExperience />
      <AboutMeHobbies />
    </main>
  )
}
  