import React from "react";
import style from './svg.module.scss'

function RightBracket() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="62"
      fill="none"
      viewBox="0 0 19 62"
    >
      <path 
        stroke="#F8C83C" 
        strokeWidth="4" 
        className={style.path2}
        d="M0 59.5h17V2H0"></path>
    </svg>
  );
}

export default RightBracket;
