import React from 'react';
import connectImg from "../../../assets/images/CircleYellow.png";
import asterisk from "../../../assets/images/asterisk.svg";
import { EmailIcon, LinkedinIcon } from '../../icons/icons';


import style from './aboutMeConnect.module.scss';


const AboutMeConnect = () => {
  return (
    <section className={style.container} id="contact">
        <div className={style.imgContainer}>
            <img className={style.image} src={connectImg} alt=""/>
        </div>
        <div className={style.textContainer}>
            <div className={style.headingWrapper}><img src={asterisk} alt="" className={style.asterisk} /><h2>Let's Connect</h2><img src={asterisk} alt="" className={style.asterisk} /></div>
            <hr className={style.lineBreak}/>
            <div className={style.connectTextWrapper}>
            <p className={style.connectText}>I am currently <span className={style.blueText}>open to connecting</span> regarding in-house, freelance or contract roles in mission-driven organizations that share my <span className={style.blueText}>values</span>.</p> 
            </div>
            <div className={style.linksWrapper}>
              <a href="mailto:kbermangestring@gmail.com" className={style.link}><EmailIcon className={style.icon} /><span>kbermangestring@gmail.com</span></a>
              <a href="https://www.linkedin.com/in/kyrabermangestring/" rel="noreferrer" target="_blank" className={style.link}><LinkedinIcon className={style.icon} /><span>@kyrabermangestring</span></a>
            </div>
        </div>
    </section>
  )
}

export default AboutMeConnect