import React from 'react';
import kyra from '../../assets/images/kyraSketch.png'
import underline from '../../assets/images/LPUnderline.svg';
import underwiggle from '../../assets/images/LPWigglyLine.svg';
import style from './hero.module.scss';
import LeftBracket from '../../assets/svg/leftBracket';
import RightBracket from '../../assets/svg/rightBracket';


export default function Hero({ isMobile }) {
  return (
    <section className={style.heroContainer}>
        {isMobile ? <div></div> : <img className={style.heroImg} src={kyra} alt='Digital drawling of Kyra&apos;s bust. She has bangs, curly hair, bright blue eyes, a septum piercing' /> }
        <div className={style.textWrapper}>
            <p className={style.text}>
              Hi, I’m Kyra, a <span className={style.yellowText}>UI/UX Designer </span> who believes that design, is an opportunity to <span className={style.yellowBackground}> solve problems,</span> <span className={style.thoughtfully}>thoughtfully, <div className={style.yellowUnderlineWrapper}><img src={underline} className={style.yellowUnderline} alt=""/></div></span>
                <span className={style.creatively}>creatively & <div className={style.wiggleLineWrapper}><span className={style.cover}></span><img src={underwiggle} className={style.wiggleLine} alt=""/></div></span>   
                <span className={style.emphatically}><div className={style.bracketLeft}><LeftBracket /></div> empathetically.<div className={style.bracketRight}><RightBracket /></div></span>
            </p>
        </div>
    </section>
  )
}
