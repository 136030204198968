import React from 'react';
import ReactGA from 'react-ga';
import campus from '../../assets/images/CampusDiveHome.png';
import nbnbHeader from '../../assets/images/nbnbHeader.png';
import mny from '../../assets/images/MNY-header.png';
import google from '../../assets/images/googleHeaderMobile.png';
import fff from '../../assets/images/FFF_Header_Small.png';
import AnimationProjectCard from '../../components/cards/homeCards/animationProjectCard';
import PortfolioPageCard from '../../components/cards/portfolioPage/portfolioPageCard';
import style from './portfolioPage.module.scss';

export default function PortfolioPage() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  React.useEffect(()=> {
    window.scrollTo({top: 0})
  },[])

  return (
    <main className={style.portfolioContainer}>
      <h2 className={style.heading2}>UI / UX Product Design Portfolio</h2> 
      <AnimationProjectCard 
        heading3="Stategic UI for diverse representation & inclusion"
        heading4="UX / UI FEATURE DESIGN"
        heading4SubText="Mobile Native App"
        text="Strategic Visual Design for Inclusive Golf Social media app that wants an approachable brand for an ever-diversifying golf industry."
        animationText="INCLUSIVE  - APP - GOLF - SOCIAL  -"
        flipped={false}
        campus={false}
        headerImg={nbnbHeader}
        to="/NBNB"
        imgClassName={style.nbnbImg}
      />
      <AnimationProjectCard 
        heading3="Redesigning Intake for optimal Impact"
        heading4="UX / UI FEATURE DESIGN "
        heading4SubText="B2C Edtech, Web"
        text="Collaborating with key stakeholders to redesign intake process for improved user experience."
        animationText="UI - DESIGN SYSTEM - SCALABLE - GROWTH - "
        flipped={true}
        campus={true}
        headerImg={campus}
        to="/CampusDive"
        imgClassName={style.campusImg}
      />
      <PortfolioPageCard 
        heading3="Empowering Equity through Financial Literacy"
        heading4="End-To-End Design"
        heading4SubText=" Fintech, Native App"
        text="Mobile app design collaboration that empowers queer folks to grow their financial confidence."
        headerImg={mny}
        to="/MNY"
        imgClassName={style.mnyImg}
      />
      <PortfolioPageCard 
        heading3="Supporting users in finding representation and equity in films"
        heading4="End-to-end Design"
        heading4SubText="Web Design, UI/UX Design"
        text="User research and product design for an educational site that allows users to find and share feminist movies."
        headerImg={fff}
        to="/FeministFilmFinder"
        imgClassName={style.fffImg}
      />
      <PortfolioPageCard 
        heading3="Improving teacher’s experience of Google Classroom"
        heading4="New Feature Design"
        heading4SubText="Edtech, Conceptual Project"
        text="Improve teacher’s experience through heuristic design and adding a new feature."
        headerImg={google}
        to="/GoogleClassroom"
        imgClassName={style.googleImg}
      />

    </main>
  )
}
