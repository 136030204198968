import React from 'react';
import { ComingSoonIFrame, HeadingCard, OverviewCard } from '../../../components/cards/portfolioCards/portfolioCards';
import nbnbHeader from '../../../assets/images/nbnbHeader.png';
import ReactGA from 'react-ga';

import style from './nbnb.module.scss';
import Carousel from '../../../components/carousel/carousel';


function NBNB() {
    const [screenSize, setScreenSize] = React.useState(null);
    ReactGA.pageview(window.location.pathname + window.location.search);

    React.useEffect(()=> {
      checkIsMobile();
      window.scrollTo({top: 0})
    },[]);
  

    const checkIsMobile = () => {
      setScreenSize(window.innerWidth);
    }
  
    window.addEventListener('resize', checkIsMobile)
    const scollToRef = React.useRef();


    return (
        <main className={style.nbnbContainer}>
            <HeadingCard product={true} lineBreak={screenSize < 671} isMobile={screenSize <= 759 ? true : false} heading="NBNB golf club" imgClassName={style.headingImg} className={style.headingUl} className2={style.headingCard} subheading="Mobile App Product Design" headerImg={nbnbHeader} imgAltText="" list={["Brand & Visual Strategy", "Maze User Testing", "Web & Circle Design","WCAG Design"]} link="https://www.nbnbgolfclub.com/" />
            <OverviewCard ref={scollToRef} seeStatus={false} seeFigma={true} text="No Bunkers No Bogeys is a social platform that fosters a supportive community for the often overlooked and underrepresented golfers. Developed originally as a mobile app, the product shifted gears and is currently live as a Circle community. The platform will help build community, learning and secure investors with the end goal of launching a mobile app as the V2 of the product." link="https://www.figma.com/proto/lncVGVCEkOklXvnMrMA2Nn/NBNB-Case-Study?page-id=0%3A1&type=design&node-id=202-1474&viewport=-670%2C896%2C0.5&t=6G5cRaJKm1Jj08K2-1&scaling=min-zoom" />
            <ComingSoonIFrame />
            <iframe className={style.iframe}  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F2Jwsp4223g1eIBw4VNnrga%2FKBG-Design-Website%3Fpage-id%3D1429%253A7753%26type%3Ddesign%26node-id%3D1434-11836%26viewport%3D452%252C380%252C0.04%26t%3D3YkWEdeKwArapD8g-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D1434%253A11836%26mode%3Ddesign" allowfullscreen title="No Bunkers, No Boogies Figma Case Study" ></iframe>        

            {/* make mobile carousel later */}
            {screenSize > 600 ? 
            <>
              <Carousel id="nbnb" /> 
            </> :
            <div></div>}
        </main>
   
   )
}

export default NBNB