import React from 'react';
import { HeadingCard, ComingSoonIFrame, OverviewCard } from '../../../components/cards/portfolioCards/portfolioCards';
import headerImg from '../../../assets/images/CampusDiveHome.png';
import ReactGA from 'react-ga';

  
import style from './cd.module.scss';
import Carousel from '../../../components/carousel/carousel';


function CdPreview() {
    const [screenSize, setScreenSize] = React.useState(null);
    ReactGA.pageview(window.location.pathname + window.location.search);

    React.useEffect(()=> {
      checkIsMobile();
      window.scrollTo({top: 0})
    },[]);
  

    const checkIsMobile = () => {
      setScreenSize(window.innerWidth);
    }
  
    window.addEventListener('resize', checkIsMobile)
    const scollToRef = React.useRef();

    return (
        <main className={style.cdContainer}>
            <HeadingCard lineBreak={screenSize < 671} isMobile={screenSize <= 759 ? true : false} product={true} heading="Supporting the college decision" imgClassName={style.headingImg} className={style.headingUl} className2={style.headingCard} className3={style.shorten} subheading="B2C Redesign & Product Design" headerImg={headerImg} imgAltText="White background with a laptop and phone each displaying the campus dive website" list={["Data Visualization", "Competitive Analysis", "Feature Design", "Developer Handoff"]} link="https://campusdive.com/"/>
            <OverviewCard ref={scollToRef} seeStatus={false} seeFigma={true} text="Campus Dive is an invaluable tool for prospective college students in helping them decide what school will be a good fit. Through a unique offering of first-hand interviews of students detailing the campus culture, social life, and academic experience, students get an honest account of what a school is really like. The UX Team was brought on to make improvements to the branding, UI and UX to strengthen the product for the over 400 current users and grow the user base as the company prepares to expand their offerings into a full suite of college application tools." link="https://www.figma.com/proto/aDfIzyfVHCDgP5EOZ574Ea/Campus-Dive-Case-Study?page-id=0%3A1&type=design&node-id=1-80&viewport=638%2C3734%2C0.5&scaling=min-zoom" />
            <ComingSoonIFrame />
            <iframe className={style.iframe}  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F2Jwsp4223g1eIBw4VNnrga%2FKBG-Design-Website%3Fpage-id%3D1429%253A371%26type%3Ddesign%26node-id%3D1434-11190%26viewport%3D812%252C127%252C0.05%26t%3DaVjs0h8LJYG2hNzk-1%26scaling%3Dscale-down-width%26mode%3Ddesign" allowfullscreen title="Campus Dive Figma Case Study" ></iframe>        
            {/* <hr className={style.lineBreak}/>
            <Carousel id="campus" /> */}
            {screenSize > 600 ? 
            <>
              <Carousel id="campus" /> 
            </> :
            <div></div>}
        </main>
    )
}

export default CdPreview