import React from 'react';
import style from './aboutMeExperience.module.scss';
import { BlueButtonInternalLink } from '../../buttons/buttons';
import arrow from './../../../assets/images/Arrow1.svg';


const AboutMeExperience = () => {
  return (
    <section className={style.container}>
        <h2>Experience</h2>
        <div className={style.articleWrapper}>
          <div className={style.graphicContainer}>
              <span className={style.bold}>Mission-Driven Product Designer</span>
              <img className={style.arrow} src={arrow} alt=""/>
              <span>High-School Art Teacher</span>
              <img className={style.arrow} src={arrow} alt=""/>
              <span>Non-Profit Management</span>
          </div>
          <article className={style.textContainer}>
              <p className={style.text}>As a UI/UX Designer, I am passionate about blending my creative, analytical, and empathetic skills into my work. I believe that no human is just one thing, and I am proud of the skills I have learned in my former careers that got me to where I am today.</p> 
              <p className={style.text}>As a former high-school art teacher, I am skilled at translating complex processes into simple steps and considering the diverse engagement needs of those I work with. As a non-profit manager, I collaborated with stakeholders to meet business and consumer interests and gained strong communication and presentation skills.</p>
              <p className={style.text}>Empathy, equity and creativity are values I have woven into my work throughout my careers.</p>
              <div className={style.buttonWrapper}>
                  <BlueButtonInternalLink className={style.button} text="View Resume PDF" to="/resume" />
              </div>
          </article>
        </div>
    </section>
  )
}

export default AboutMeExperience