import React from "react";
import style from './svg.module.scss'

function LeftBracket() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="62"
        fill="none"
        viewBox="0 0 19 62"
    >
        <path 
            stroke="#F8C83C" 
            strokeWidth="4" 
            className={style.path}
            d="M19 2H2v57.5h17"></path>
    </svg>
  );
}

export default LeftBracket;