import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import style from './desktopNav.module.scss';

export default function DesktopNav() {
    const [subNavIsOpen, setSubNavIsOpen] =useState(false);
    const node = useRef();
  
    useEffect(() => {
      document.addEventListener('mousedown', clickOutside);
      return () => {
        document.removeEventListener('mousedown', clickOutside);
      }
    }, [subNavIsOpen])
        
    const clickOutside = (e) => {
      if(!node.current.contains(e.target)) {
        setSubNavIsOpen(false)
      }
    }

    const handleClick = () =>{
      setSubNavIsOpen(false)
    }

    return (
      <nav className={style.navWrapper}>
        <ul className={style.mainNav}  ref={node}>
            <li className={style.headerLI}><Link to="/portfolio" onClick={handleClick}>Portfolio</Link></li>
            <li className={style.headerLI}><Link onClick={handleClick} to="/about">About</Link></li>
            <li className={style.headerLI}><NavHashLink onClick={handleClick} smooth to="/about#contact" >Contact</NavHashLink></li>
            <li className={style.headerLI}><Link onClick={handleClick} to="/resume">Resume</Link></li>
        </ul>
        
      </nav>
    )
  }
  
